.block {
    &--pagination {
        margin: 0 0 ($default-block-margin - $default-margin);
    }
}

.pagination {
    margin: 0 0 $default-margin;
    border-radius: 0;

    &-list {
        display: flex;
        flex-wrap: wrap;
        width: auto;
        justify-content: center;
        margin: 0 auto;
        background: rgba($color-black, .2);
        border-radius: 50px;
        padding: 4px;

        .page-item {
            display: inline-block;
            margin: 0 2px;

            &:first-child,
            &:last-child {
                .page-link {
                    border-radius: 50%;
                }
            }

            &.active {
                .page-link {
                    font-weight: 900;
                    background-color: $color-red;
                }
            }

            &.control {
                @include media-breakpoint-down(md) {
                    display: none;
                }

                .page-link {
                    @include icon(arrowthin, $color-white, 17px, 17px);
                    background-color: $color-black;
                    text-indent: -99999px;
                    transform: rotate(90deg);

                    &:hover,
                    &:focus {
                        background-color: $color-red;
                    }
                }

                &.next {
                    .page-link {
                        transform: rotate(-90deg);
                    }
                }
            }
        }

        .page-link {
            display: block;
            width: 32px;
            height: 32px;
            padding: 7px 0;
            color: $color-white;
            font-size: 16px;
            line-height: 18px;
            text-align: center;
            overflow: hidden;
            border-radius: 50%;
            background-color: $color-black;
            border: 0;
            font-weight: 900;

            &:not(span) {
                &:hover,
                &:focus {
                    color: $color-white;
                    background-color: $color-red;
                }
            }
        }
    }
}

// Landingspage 2

.landingspage-2 {
    .pagination {
        .page-item {
            &.active {
                .page-link {
                    background-color: $color-blue;
                }
            }

            &.control {
                .page-link {
                    &:hover,
                    &:focus {
                        background-color: $color-blue;
                    }
                }
            }
        }

        .page-link {
            &:not(span) {
                &:hover,
                &:focus {
                    background-color: $color-blue;
                }
            }
        }
    }
}
