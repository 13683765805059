// SVG icons
@mixin icon($type, $color, $width, $height) {
    @if $type == facebook {
        background-image: svg-uri('<svg xmlns="http://www.w3.org/2000/svg" width="' + $width + '" height="' + $height + '" viewBox="0 0 40 40"><style>.st0{fill: ' + $color + ';}</style><path class="st0" d="M25,14.3h-2.8c-0.3,0-0.7,0.5-0.7,1.1v1.8H25v3.5h-3.5v8.5h-3.5v-8.5H15v-3.5h2.8v-1.8c-0.1-2.4,1.8-4.5,4.2-4.6H25L25,14.3z"/></svg>');
    } @else if $type == facebook2 {
        background-image: svg-uri('<svg xmlns="http://www.w3.org/2000/svg" width="' + $width + '" height="' + $height + '" viewBox="0 0 40 40"><style>.st0{fill: ' + $color + ';}</style><path class="st0" d="M20,0C9,0,0,9,0,20s9,20,20,20c11,0,20-9,20-20S31,0,20,0z M24.5,14.1h-2.8c-0.3,0-0.7,0.5-0.7,1.1V17h3.5v3.5h-3.5V29h-3.5v-8.5h-2.8V17h2.8v-1.8c-0.1-2.4,1.8-4.5,4.2-4.6h2.8L24.5,14.1z"/></svg>');
    } @else if $type == twitter {
        background-image: svg-uri('<svg xmlns="http://www.w3.org/2000/svg" width="' + $width + '" height="' + $height + '"  viewBox="0 0 50 50" ><style>.st0 {fill: ' + $color + ';}</style><path class="st0" d="M 6.9199219 6 L 21.136719 26.726562 L 6.2285156 44 L 9.40625 44 L 22.544922 28.777344 L 32.986328 44 L 43 44 L 28.123047 22.3125 L 42.203125 6 L 39.027344 6 L 26.716797 20.261719 L 16.933594 6 L 6.9199219 6 z"></path></svg>');
    } @else if $type == twitter2 {
        background-image: svg-uri('<svg xmlns="http://www.w3.org/2000/svg" shape-rendering="geometricPrecision" text-rendering="geometricPrecision" image-rendering="optimizeQuality" fill-rule="evenodd" clip-rule="evenodd" viewBox="0 0 512 512" width="' + $width + '" height="' + $height + '"><style>.st0 {fill: ' + $color + ';}</style><path class="st0" d="M256 0c141.384 0 256 114.616 256 256 0 141.384-114.616 256-256 256C114.616 512 0 397.384 0 256 0 114.616 114.616 0 256 0zm62.64 157.549h33.401l-72.974 83.407 85.85 113.495h-67.222l-52.645-68.837-60.244 68.837h-33.422l78.051-89.212-82.352-107.69h68.924l47.59 62.916 55.043-62.916zm-11.724 176.908h18.509L205.95 176.494h-19.861l120.827 157.963z"/></svg>');
    } @else if $type == youtube {
        background-image: svg-uri('<svg xmlns="http://www.w3.org/2000/svg" width="' + $width + '" height="' + $height + '" viewBox="0 0 40 40"><style>.st0 {fill: ' + $color + ';}</style><path class="st0" d="M30.6,19.6c0-2.2,0-4.8-0.8-5.9c-0.7-1.1-2-1.1-3.8-1.2c-2.8-0.1-5.9-0.2-8.7-0.1c-1.1,0-2.2,0-3.3,0.1c-1-0.1-2,0.1-3,0.3c-1.8,0.7-1.9,2.9-2,5.3c-0.1,2.8-0.2,6.9,1,8.3c0.9,1,3.1,1,4.9,1c3.2,0.1,6.1,0,9.3,0c1.5,0.1,2.9,0,4.3-0.4v0C30.7,26,30.6,22.8,30.6,19.6z M17.6,22.6v-6c2,1,3.8,2,5.8,3C21.5,20.7,19.5,21.6,17.6,22.6z"/></svg>');
    } @else if $type == youtube2 {
        background-image: svg-uri('<svg xmlns="http://www.w3.org/2000/svg" width="' + $width + '" height="' + $height + '" viewBox="0 0 40 40"><style>.st0 {fill: ' + $color + ';}</style><g transform="translate(-800.148 -315)"><path class="st0" d="M820.1,315c-11,0-20,9-20,20s9,20,20,20c11,0,20-9,20-20S831.2,315,820.1,315z M828.8,341.9c-1.4,0.4-2.9,0.5-4.3,0.4c-3.2,0-6.1,0.1-9.3,0c-1.9,0-4,0-4.9-1c-1.3-1.3-1.2-5.5-1-8.3c0.1-2.4,0.2-4.6,2-5.3c1-0.3,2-0.4,3-0.3c1.1,0,2.1,0,3.3-0.1c2.7-0.1,5.8,0,8.7,0.1c1.8,0.1,3,0.2,3.8,1.2c0.8,1.2,0.8,3.7,0.8,5.9C830.7,337.8,830.9,341,828.8,341.9L828.8,341.9z"/><path class="st0" d="M817.7,337.6c1.9-1,3.9-1.9,5.8-3c-2-1-3.8-2-5.8-3V337.6z"/></g></svg>');
    } @else if $type == weibo {
        background-image: svg-uri('<svg xmlns="http://www.w3.org/2000/svg" width="' + $width + '" height="' + $height + '" viewBox="0 0 512 512"><style>.st0 {fill: ' + $color + ';}</style><path d="M195.532,308.67c-7.7,3.829-10.986,12.877-7.339,20.21s12.845,10.173,20.544,6.343,10.986-12.877,7.339-20.209S203.232,304.84,195.532,308.67Z"/><path class="st0" d="M225.915,301.507c-3.287,1.634-4.773,5.33-3.319,8.254s5.3,3.969,8.584,2.335,4.774-5.331,3.32-8.255S229.2,299.872,225.915,301.507Z"/><path class="st0" d="M256,0C114.615,0,0,114.615,0,256S114.615,512,256,512,512,397.385,512,256,397.385,0,256,0ZM358.913,304.86c-6.33,19.844-21.1,41.2-54.83,58.748-22.063,11.473-51.405,20.895-82.6,20.895-103.769,0-112.152-65.037-112.152-78.4,0-40.084,36.743-96.868,70.146-116.91s53.444-26.722,66.806-13.36c7.068,7.068,6.367,23.21,2.22,36.145a3.644,3.644,0,0,0,5.427,4.189c10.73-6.805,28.019-15.527,45.929-15.331,29.285.32,25.765,28.806,24.485,35.527A53.526,53.526,0,0,1,321,246.251a6.89,6.89,0,0,0,4.948,9.464,66.342,66.342,0,0,1,19.045,6.9C357.952,269.973,363.5,290.49,358.913,304.86Zm2.706-82.764a9.686,9.686,0,0,1-18.573-5.509c1.514-5.106,1.05-15.511-4.722-22.659-4.55-5.631-11.734-8.114-21.355-7.377a9.687,9.687,0,1,1-1.479-19.317c20.364-1.562,31.858,7.036,37.9,14.521C363.54,194.317,364.77,211.473,361.619,222.1ZM399.6,233.337a10.689,10.689,0,0,1-20.384-6.446c4.538-14.347,1.993-39.018-11.66-56.478-12.256-15.675-30.689-22.856-54.777-21.341a10.689,10.689,0,1,1-1.34-21.336c39.289-2.469,61.366,14.684,72.958,29.509C403.607,181.811,405.744,213.909,399.6,233.337Z"/><path class="st0" d="M218.928,247.046c-49.786,3.479-88.27,33.145-85.955,66.257s44.551,57.135,94.338,53.654,88.269-33.144,85.955-66.257S268.715,243.565,218.928,247.046ZM226,353.354c-25.848,5.73-50.8-7.67-55.735-29.93s12.019-44.949,37.867-50.679,50.8,7.672,55.735,29.931S251.85,347.625,226,353.354Z"/></svg>');
    } @else if $type == wechat {
        background-image: svg-uri('<svg xmlns="http://www.w3.org/2000/svg" width="' + $width + '" height="' + $height + '" viewBox="0 0 512 512"><style>.st0 {fill: ' + $color + ';}</style><path class="st0" d="M179.885,182.329a14.658,14.658,0,1,0,14.657,14.658A14.658,14.658,0,0,0,179.885,182.329Z"/><path class="st0" d="M250.735,211.645a14.658,14.658,0,1,0-14.658-14.658A14.657,14.657,0,0,0,250.735,211.645Z"/><path class="st0" d="M284.909,256.673a12.207,12.207,0,1,0,12.207,12.206A12.206,12.206,0,0,0,284.909,256.673Z"/><path class="st0" d="M256,0C114.615,0,0,114.615,0,256S114.615,512,256,512,512,397.385,512,256,397.385,0,256,0ZM215.31,313.5a124.921,124.921,0,0,1-36.6-5.406,8.817,8.817,0,0,0-7.369,1.024l-23.838,15.395a2.992,2.992,0,0,1-4.523-3.25l5.265-20.738a8.825,8.825,0,0,0-3.3-9.269C123.1,275.1,109.333,251.544,109.333,225.31c0-48.706,47.448-88.19,105.977-88.19,52.846,0,96.654,32.191,104.662,74.295-1.841-.09-3.695-.14-5.562-.14-52.936,0-96,36.422-96,81.191a69.355,69.355,0,0,0,3.222,20.874C219.537,313.442,217.432,313.5,215.31,313.5Zm157.883,33.749a7.754,7.754,0,0,0-2.864,8.134l4,15.758a2.992,2.992,0,0,1-4.523,3.25l-18.616-12.022a7.783,7.783,0,0,0-6.494-.9,104.085,104.085,0,0,1-30.286,4.443c-48.743,0-88.257-32.882-88.257-73.444s39.514-73.444,88.257-73.444,88.256,32.882,88.256,73.444C402.667,314.24,391.28,333.8,373.193,347.249Z"/><path class="st0" d="M343.912,256.673a12.207,12.207,0,1,0,12.207,12.206A12.206,12.206,0,0,0,343.912,256.673Z"/></svg>');
    } @else if $type == tiktok {
        background-image: svg-uri('<svg xmlns="http://www.w3.org/2000/svg" width="' + $width + '" height="' + $height + '" viewBox="0 0 512 512"><style>.st0 {fill: ' + $color + ';}</style><path class="st0" d="M256,0C114.615,0,0,114.615,0,256S114.615,512,256,512,512,397.385,512,256,397.385,0,256,0ZM385.62,232.382c-27.184,0-53.634-8.822-74-23.75l-.162,101.5a92.457,92.457,0,1,1-80.178-91.721v49.845a43.657,43.657,0,1,0,31.288,41.876V109.333h51.275a71.773,71.773,0,0,0,71.774,71.773Z"/></svg>');
    } @else if $type == linkedin {
        background-image: svg-uri('<svg xmlns="http://www.w3.org/2000/svg" width="' + $width + '" height="' + $height + '" viewBox="0 0 40 40"><style>.st0 {fill: ' + $color + ';}</style><path class="st0" d="M16.7,27.1h-2.8v-9.7h2.8V27.1z M15.3,16.3c-1,0-1.7-0.8-1.7-1.7c0,0,0,0,0,0c0-1,0.8-1.7,1.7-1.7c0,0,0,0,0,0c1,0,1.7,0.8,1.7,1.7c0,0,0,0,0,0C17,15.6,16.3,16.3,15.3,16.3L15.3,16.3z M26.4,27.1h-2.8v-5.9c0,0,0.2-1.4-1.4-1.4c-1.4,0-1.3,1-1.3,1v6.2h-2.8v-9.7h2.8v0.4c1.2-0.5,2.5-0.5,3.7,0.1c1.1,0.7,1.9,1.9,1.9,3.3V27.1z"/></svg>');
    } @else if $type == linkedin2 {
        background-image: svg-uri('<svg xmlns="http://www.w3.org/2000/svg" width="' + $width + '" height="' + $height + '" viewBox="0 0 40 40"><style>.st0 {fill: ' + $color + ';}</style><path class="st0" d="M20,0C9,0,0,9,0,20s9,20,20,20s20-9,20-20S31,0,20,0z M16.7,27.1h-2.8v-9.7h2.8V27.1z M15.3,16.3c-1,0-1.7-0.8-1.7-1.7c0,0,0,0,0,0c0-1,0.8-1.7,1.7-1.7c0,0,0,0,0,0c1,0,1.7,0.8,1.7,1.7c0,0,0,0,0,0C17,15.6,16.3,16.3,15.3,16.3L15.3,16.3z M26.4,27.1h-2.8v-5.9c0,0,0.2-1.4-1.4-1.4c-1.4,0-1.3,1-1.3,1v6.2h-2.8v-9.7h2.8v0.4c1.2-0.5,2.5-0.5,3.7,0.1c1.1,0.7,1.9,1.9,1.9,3.3V27.1z"/></svg>');
    } @else if $type == instagram {
        background-image: svg-uri('<svg xmlns="http://www.w3.org/2000/svg" width="' + $width + '" height="' + $height + '" viewBox="0 0 40 40"><style>.st0 {fill: ' + $color + ';}</style><g transform="translate(-599.851 -315)"><path class="st0" d="M619.8,332.1L619.8,332.1c-0.8,0-1.4,0.3-1.9,0.8c-0.6,0.5-0.9,1.3-0.9,2.1c0,1.6,1.3,2.9,2.9,2.9c1.6,0,2.9-1.3,2.9-2.9c0-0.7-0.2-1.4-0.7-2C621.4,332.4,620.6,332.1,619.8,332.1z"/><path class="st0" d="M623.6,327.4h-7.4c-2,0-3.6,1.6-3.6,3.6l0,0v7.9c0,2,1.6,3.6,3.6,3.6l0,0h7.4c2,0,3.6-1.6,3.6-3.6l0,0V331C627.2,329,625.6,327.4,623.6,327.4z M625.2,335c0,1.4-0.6,2.7-1.6,3.7c-1,1-2.3,1.6-3.8,1.6c-2.9,0-5.3-2.4-5.3-5.3c0-1.5,0.6-2.9,1.6-3.9c1-1,2.3-1.5,3.7-1.5c1.5,0,2.9,0.6,4,1.6C624.8,332.3,625.3,333.6,625.2,335L625.2,335z M624.9,331.1c-0.6,0-1.1-0.5-1.1-1.1c0,0,0,0,0,0c0-0.6,0.5-1.1,1.1-1.1c0,0,0,0,0,0c0.6,0,1.1,0.5,1.1,1.1c0,0,0,0,0,0C626,330.5,625.5,331.1,624.9,331.1C624.9,331.1,624.9,331.1,624.9,331.1L624.9,331.1z"/></g></svg>');
    } @else if $type == instagram2 {
        background-image: svg-uri('<svg xmlns="http://www.w3.org/2000/svg" width="' + $width + '" height="' + $height + '" viewBox="0 0 40 40"><style>.st0 {fill: ' + $color + ';}</style><g transform="translate(-599.851 -315)"><path class="st0" d="M619.8,332.1L619.8,332.1c-0.8,0-1.4,0.3-1.9,0.8c-0.6,0.5-0.9,1.3-0.9,2.1c0,1.6,1.3,2.9,2.9,2.9c1.6,0,2.9-1.3,2.9-2.9c0-0.7-0.2-1.4-0.7-2C621.4,332.4,620.6,332.1,619.8,332.1z"/><path class="st0" d="M623.6,327.4h-7.4c-2,0-3.6,1.6-3.6,3.6l0,0v7.9c0,2,1.6,3.6,3.6,3.6l0,0h7.4c2,0,3.6-1.6,3.6-3.6l0,0V331C627.2,329,625.6,327.4,623.6,327.4z M625.2,335c0,1.4-0.6,2.7-1.6,3.7c-1,1-2.3,1.6-3.8,1.6c-2.9,0-5.3-2.4-5.3-5.3c0-1.5,0.6-2.9,1.6-3.9c1-1,2.3-1.5,3.7-1.5c1.5,0,2.9,0.6,4,1.6C624.8,332.3,625.3,333.6,625.2,335L625.2,335z M624.9,331.1c-0.6,0-1.1-0.5-1.1-1.1c0,0,0,0,0,0c0-0.6,0.5-1.1,1.1-1.1c0,0,0,0,0,0c0.6,0,1.1,0.5,1.1,1.1c0,0,0,0,0,0C626,330.5,625.5,331.1,624.9,331.1C624.9,331.1,624.9,331.1,624.9,331.1L624.9,331.1z"/><path id="Path_698" class="st0" d="M619.9,315c-11,0-20,9-20,20s9,20,20,20c11,0,20-9,20-20S630.9,315,619.9,315z M629.2,339c0,3.1-2.5,5.6-5.6,5.6h-7.4c-3.1,0-5.6-2.5-5.6-5.6V331c0-3.1,2.5-5.6,5.6-5.6h7.4c3.1,0,5.6,2.5,5.6,5.6L629.2,339z"/></g></svg>');
    } @else if $type == pinterest {
        background-image: svg-uri('<svg xmlns="http://www.w3.org/2000/svg" width="' + $width + '" height="' + $height + '" viewBox="0 0 40 40"><style>.st0 {fill: ' + $color + ';}</style><path class="st0" d="M25.3,23.7c-1.2,1.2-4.3,1.9-6,0.7c-0.2-0.1-0.3-0.8-0.7-0.7c-0.4,2-1.1,4.4-2,5.8h-1.2c-0.2-0.7-0.1-2.2,0-3c0.3-1.9,1.6-4.6,1.6-6.5c0-0.8-0.4-1.7-0.3-2.6c0.3-1.8,2.9-3.4,3.8-1.3c1,2.2-2.1,5.1-0.4,6.6c0.4,0.3,1.2,0.6,2,0.4c3.6-0.8,4-8.2,1.1-9.9c-4.3-2.6-9.1,1.2-8.8,5.4c0.2,1.5,1.8,2.4,0.4,3.7c-1.9-0.9-2.7-2.2-2.7-4.5c0-4.8,4.5-7.8,9.6-7.3c3.1,0.3,5.8,2.9,6.2,5.7C28,18.8,27.2,22.1,25.3,23.7z"/></svg>');
    } @else if $type == pinterest2 {
        background-image: svg-uri('<svg xmlns="http://www.w3.org/2000/svg" width="' + $width + '" height="' + $height + '" viewBox="0 0 40 40"><style>.st0 {fill: ' + $color + ';}</style><path class="st0" d="M20,0C9,0,0,9,0,20s9,20,20,20s20-9,20-20S31,0,20,0z M25.3,23.7c-1.2,1.2-4.3,1.9-6,0.7c-0.2-0.1-0.3-0.8-0.7-0.7c-0.4,2-1.1,4.4-2,5.8h-1.2c-0.2-0.7-0.1-2.2,0-3c0.3-1.9,1.6-4.6,1.6-6.5c0-0.8-0.4-1.7-0.3-2.6c0.3-1.8,2.9-3.4,3.8-1.3c1,2.2-2.1,5.1-0.4,6.6c0.4,0.3,1.2,0.6,2,0.4c3.6-0.8,4-8.2,1.1-9.9c-4.3-2.6-9.1,1.2-8.8,5.4c0.2,1.5,1.8,2.4,0.4,3.7c-1.9-0.9-2.7-2.2-2.7-4.5c0-4.8,4.5-7.8,9.6-7.3c3.1,0.3,5.8,2.9,6.2,5.7C28,18.8,27.2,22.1,25.3,23.7z"/></svg>');
    } @else if $type == whatsapp {
        background-image: svg-uri('<svg xmlns="http://www.w3.org/2000/svg" width="' + $width + '" height="' + $height + '" viewBox="0 0 20 20"><style>.st0 {fill: ' + $color + ';}</style><g><path class="st0" d="M18.5,9.8c0,4.6-3.7,8.3-8.3,8.3c-1.5,0-2.8-0.4-4-1l-4.6,1.5L3,14.1c-0.8-1.2-1.2-2.7-1.2-4.3c0-4.6,3.7-8.3,8.3-8.3C14.8,1.5,18.5,5.2,18.5,9.8z M10.2,2.8c-3.9,0-7,3.1-7,7c0,1.5,0.5,2.9,1.3,4.1l-0.9,2.6l2.7-0.9c1.1,0.7,2.4,1.2,3.9,1.2c3.9,0,7-3.1,7-7S14,2.8,10.2,2.8z M14.4,11.7c-0.1-0.1-0.2-0.1-0.4-0.2c-0.2-0.1-1.2-0.6-1.4-0.7c-0.2-0.1-0.3-0.1-0.5,0.1c-0.1,0.2-0.5,0.7-0.6,0.8c-0.1,0.1-0.2,0.2-0.4,0.1c-0.2-0.1-0.9-0.3-1.6-1c-0.6-0.5-1-1.2-1.1-1.4C8.1,9.1,8.2,9,8.3,8.9c0.1-0.1,0.2-0.2,0.3-0.4c0.1-0.1,0.1-0.2,0.2-0.3c0.1-0.1,0-0.3,0-0.4C8.8,7.8,8.4,6.8,8.2,6.4C8,6,7.9,6,7.7,6C7.6,6,7.5,6,7.3,6S7,6.1,6.8,6.3C6.6,6.5,6.1,6.9,6.1,7.9c0,1,0.7,2,0.8,2.1c0.1,0.1,1.4,2.3,3.5,3.1c2.1,0.8,2.1,0.5,2.5,0.5c0.4,0,1.2-0.5,1.4-1C14.4,12.2,14.4,11.8,14.4,11.7z"/></g></svg>');
    } @else if $type == newsletter {
        background-image: svg-uri('<svg xmlns="http://www.w3.org/2000/svg" width="' + $width + '" height="' + $height + '" viewBox="0 0 20 20"><style>.st0 {fill: ' + $color + ';}</style><g><g><path class="st0" d="M1,17c0-4.6,0-9.4,0-14c6,0,12,0,18,0c0,4.6,0,9.4,0,14C13,17,7,17,1,17z M17.4,4.1c-5,0-9.9,0-14.8,0c2.5,2.5,5,5.1,7.4,7.7C12.5,9.3,14.9,6.8,17.4,4.1z M7.4,10.9c-1.6,1.7-3.3,3.3-4.8,5c4.9,0,9.9,0,14.7,0c-1.6-1.7-3.3-3.3-4.8-5c-0.9,0.9-1.7,1.8-2.6,2.6C9.1,12.6,8.3,11.8,7.4,10.9z M6.6,10c-1.5-1.6-3-3.1-4.5-4.6c0,3.1,0,6.2,0,9.3C3.6,13.1,5.1,11.6,6.6,10z M17.9,5.4c-1.5,1.6-3,3.1-4.5,4.6c1.5,1.6,3,3.1,4.5,4.6C17.9,11.6,17.9,8.4,17.9,5.4z"/></g></g></svg>');
    } @else if $type == email {
        background-image: svg-uri('<svg xmlns="http://www.w3.org/2000/svg" width="' + $width + '" height="' + $height + '" viewBox="0 0 40 40"><style>.st0 {fill: ' + $color + ';}</style><path class="st0" d="M20.1,10c2.8,0,5.2,1,7.1,2.9S30,17.2,30,20c0,1.6-0.3,2.8-1,3.9c-0.6,1-1.6,1.5-2.8,1.5c-1.3,0-2.2-0.6-2.9-1.9c-0.8,1.3-2,1.9-3.6,1.9c-1.4,0-2.6-0.5-3.5-1.5c-1-1-1.4-2.3-1.4-3.8c0-1.5,0.5-2.8,1.4-3.8c1-1,2.1-1.5,3.4-1.5c1.3,0,2.4,0.5,3.3,1.5H23v-1.1h1.8v6.8c0,0.4,0.1,0.9,0.4,1.2c0.3,0.4,0.6,0.5,1.2,0.5c1.2,0,1.8-1.2,1.8-3.7c0-2.3-0.8-4.2-2.4-5.8s-3.5-2.4-5.7-2.4c-2.3,0-4.2,0.8-5.7,2.4c-1.7,1.6-2.5,3.5-2.5,5.8s0.8,4.2,2.4,5.8c1.6,1.6,3.5,2.4,5.7,2.4c1.2,0,2.3-0.2,3.3-0.7l0.7,1.8c-1.2,0.6-2.5,0.8-4,0.8c-2.8,0-5.2-1-7.2-2.9C11,25,10,22.7,10,20s1-5.1,2.9-7.1C14.8,11,17.2,10,20.1,10 M17.7,22.4c0.6,0.6,1.4,0.9,2.2,0.9c0.9,0,1.6-0.3,2.2-0.9C22.7,21.8,23,21,23,20c0-1-0.3-1.8-0.9-2.4c-0.6-0.6-1.3-0.9-2.2-0.9s-1.6,0.3-2.2,0.9c-0.6,0.6-0.9,1.4-0.9,2.4C16.8,21,17.1,21.8,17.7,22.4"/></svg>');
    } @else if $type == phone {
        background-image: svg-uri('<svg xmlns="http://www.w3.org/2000/svg" width="' + $width + '" height="' + $height + '" viewBox="0 0 40 40"><style>.st0 {fill: ' + $color + ';}</style><path class="st0" d="M24.5,27.4c-0.1,0.2-0.3,0.4-0.5,0.4l-2.8,0.6c-0.1,0-0.3,0.1-0.4,0c-0.2,0-0.3-0.1-0.5-0.1l-0.1,0c0,0-0.1-0.1-0.2-0.1c-0.3-0.2-0.5-0.4-0.7-0.6c-0.4-0.4-0.8-0.8-1.1-1.3c-0.5-0.7-0.9-1.4-1.2-2.1c-0.4-1-0.8-2.1-1-3.2c-0.2-0.9-0.4-1.8-0.5-2.7c-0.1-0.7-0.1-1.4,0-2.1c0-0.5,0.1-1.1,0.2-1.6c0.1-0.4,0.2-0.8,0.4-1.1c0.1-0.2,0.2-0.5,0.3-0.7c0-0.1,0.1-0.2,0.2-0.2c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.1,0.3-0.1,0.4-0.2l2.8-0.7c0.2,0,0.4,0,0.5,0.1c0.1,0.1,0.2,0.2,0.2,0.3C21,12,21,12.1,21,12.3l-0.3,3.5c0,0.2-0.1,0.4-0.2,0.5c-0.1,0.1-0.3,0.2-0.5,0.3l-1,0.2c0,0-0.1,0-0.1,0.1c0,0-0.1,0.1-0.1,0.1c-0.1,0.2-0.1,0.5-0.1,0.8c0,0.4,0,0.7,0,1.1c0.1,0.5,0.2,1.1,0.3,1.6c0.1,0.5,0.3,1.1,0.5,1.6c0.1,0.3,0.3,0.7,0.5,1c0.1,0.2,0.2,0.3,0.4,0.5l0.1,0.1c0,0,0,0,0.1,0h0.1l1.2-0.3c0.2,0,0.5,0,0.7,0.1c0.1,0.1,0.2,0.2,0.3,0.3h0l1.6,3C24.5,27,24.5,27.2,24.5,27.4L24.5,27.4z"/></svg>');
    } @else if $type == search {
        background-image: svg-uri('<svg xmlns="http://www.w3.org/2000/svg" width="' + $width + '" height="' + $height + '"  viewBox="0 0 18.425 18.426"><path id="np_search_684733_000000" d="M18.345,34.467l4.06-4.088a7.689,7.689,0,0,1-1.526-4.606,7.773,7.773,0,1,1,7.773,7.773,7.689,7.689,0,0,1-4.606-1.526l-4.06,4.06a1.146,1.146,0,0,1-1.641,0,1.115,1.115,0,0,1,0-1.613Zm10.307-3.224a5.47,5.47,0,1,0-5.47-5.47A5.47,5.47,0,0,0,28.652,31.243Z" transform="translate(-18 -18)" fill="' + $color + '"/></svg>');
    } @else if $type == share {
        background-image: svg-uri('<svg xmlns="http://www.w3.org/2000/svg" width="' + $width + '" height="' + $height + '" viewBox="0 0 20 20"><style>.st0 {fill: ' + $color + ';}</style><path class="st0" d="M8.7,9.9c0,0.4-0.1,0.8-0.3,1.3l3.4,2.1c1.3-1.4,3.4-1.5,4.8-0.3c1.4,1.3,1.5,3.4,0.3,4.8c-1.3,1.4-3.4,1.5-4.8,0.3c-1.1-0.9-1.4-2.4-0.8-3.6l-3.4-2.1c-1.3,1.4-3.5,1.4-4.9,0.1S1.7,9,3,7.7c1.3-1.4,3.5-1.4,4.9-0.1l0.1,0.1l3.4-2.1c-0.7-1.9,0.3-3.7,2.1-4.4s3.7,0.3,4.4,2.1c0.7,1.9-0.3,3.7-2.1,4.4c-1.3,0.4-2.8,0.1-3.7-0.9L8.7,8.9C8.5,9,8.7,9.4,8.7,9.9z"/></svg>');
    } @else if $type == menu {
        background-image: svg-uri('<svg xmlns="http://www.w3.org/2000/svg" width="' + $width + '" height="' + $height + '" viewBox="0 0 30 24"><style>.st0 {fill: ' + $color + ';}</style><g><path class="st0" d="M28.2,4H1.8C0.8,4,0,3.2,0,2.2l0-0.4C0,0.8,0.8,0,1.8,0l26.4,0c1,0,1.8,0.8,1.8,1.8v0.4C30,3.2,29.2,4,28.2,4z"/><path class="st0" d="M28.2,14H1.8c-1,0-1.8-0.8-1.8-1.8l0-0.4c0-1,0.8-1.8,1.8-1.8h26.4c1,0,1.8,0.8,1.8,1.8v0.4C30,13.2,29.2,14,28.2,14z"/><path class="st0" d="M28.2,24H1.8c-1,0-1.8-0.8-1.8-1.8l0-0.4c0-1,0.8-1.8,1.8-1.8h26.4c1,0,1.8,0.8,1.8,1.8v0.4C30,23.2,29.2,24,28.2,24z"/></g></svg>');
    } @else if $type == close {
        background-image: svg-uri('<svg xmlns="http://www.w3.org/2000/svg" width="' + $width + '" height="' + $height + '" viewBox="0 0 20 20"><style>.st0 {fill: ' + $color + ';}</style><path class="st0" d="M18,4.6L15.6,2L10,7.5l0,0l0,0L4.4,2L2,4.6L7.5,10L2,15.4L4.4,18l5.6-5.5l0,0l0,0l5.6,5.5l2.4-2.6L12.5,10L18,4.6z"/></svg>');
    } @else if $type == close2 {
        background-image: svg-uri('<svg xmlns="http://www.w3.org/2000/svg" width="' + $width + '" height="' + $height + '" viewBox="0 0 20 20"><style>.st0 {fill: ' + $color + ';}</style><polygon class="st0" points="20,0.7 19.3,0 10,9.3 0.7,0 0,0.7 9.3,10 0,19.3 0.7,20 10,10.7 19.3,20 20,19.3 10.7,10 "/></svg>');
    } @else if $type == arrow {
        background-image: svg-uri('<svg xmlns="http://www.w3.org/2000/svg" width="' + $width + '" height="' + $height + '" viewBox="0 0 36 81"><style >.st0 { fill: ' + $color + '; }</style><path class="st0" d="M34.2,81c-0.5,0-1.1-0.2-1.4-0.7L0.4,41.6c-0.5-0.6-0.5-1.5,0-2.2L33,0.6c0.6-0.7,1.6-0.8,2.3-0.2c0,0,0,0,0.1,0C36.1,1,36.2,2,35.6,2.7l0,0L3.9,40.5l31.6,37.8c0.6,0.7,0.6,1.7-0.1,2.3c0,0,0,0-0.1,0.1C35,80.8,34.6,81,34.2,81z"/></svg>');
    } @else if $type == arrow-big {
        background-image: svg-uri('<svg xmlns="http://www.w3.org/2000/svg" width="' + $width + '" height="' + $height + '" viewBox="0 0 22 34"><style >.st0 { fill: ' + $color + '; }</style><path class="st0" d="M17.1,34L0,17L17.1,0L22,4.8L9.7,17L22,29.2L17.1,34z"/></svg>');
    } @else if $type == arrow-small {
        background-image: svg-uri('<svg xmlns="http://www.w3.org/2000/svg" width="' + $width + '" height="' + $height + '" viewBox="0 0 20 20"><style >.st0 { fill: ' + $color + '; }</style><g><polygon class="st0" points="14.4,0 15,0.7 6.3,10 15,19.3 14.4,20 5,10 	"/></g></svg>');
    } @else if $type == select {
        background-image: svg-uri('<svg xmlns="http://www.w3.org/2000/svg" width="' + $width + '" height="' + $height + '" viewBox="0 0 20 6"><style>.st0{fill:' + $color + ';}</style><path class="st0" d="M10.6,0.2c0.4-0.3,0.9-0.3,1.2,0.1l0,0c0.3,0.4,0.3,0.9-0.1,1.2l-5,4.3 c-0.3,0.3-0.8,0.3-1.1,0L0.3,1.6c-0.4-0.3-0.4-0.9-0.1-1.2C0.5,0,1-0.1,1.4,0.2l0,0L6.1,4L10.6,0.2z"/></svg>');
    } @else if $type == play {
        background-image: svg-uri('<svg xmlns="http://www.w3.org/2000/svg" width="' + $width + '" height="' + $height + '" viewBox="0 0 18 18"><style>.st0{fill:' + $color + ';}</style><path class="st0" d="M15.5615866,8.10002147 L3.87056367,0.225209313 C3.05219207,-0.33727727 2,0.225209313 2,1.12518784 L2,16.8748122 C2,17.7747907 3.05219207,18.3372773 3.87056367,17.7747907 L15.5615866,9.89997853 C16.1461378,9.44998927 16.1461378,8.55001073 15.5615866,8.10002147 L15.5615866,8.10002147 Z"></path></svg>');
    } @else if $type == checkicon {
        background-image: svg-uri('<svg id="Laag_1" data-name="Laag 1" xmlns="http://www.w3.org/2000/svg" width="' + $width + '" height="' + $height + '" viewBox="0 0 70.2 70.2"><path fill="' + $color + '" d="M35.1,70.2A35.1,35.1,0,1,0,0,35.1,35.14,35.14,0,0,0,35.1,70.2Zm0-66.8A31.7,31.7,0,1,1,3.4,35.1,31.71,31.71,0,0,1,35.1,3.4ZM31.2,44.2l-6.89-6.89,2.39-2.4,4.61,4.6L43.7,27.11l2.4,2.4Z"/></svg>');
    } @else if $type == globe {
        background-image: svg-uri('<svg xmlns="http://www.w3.org/2000/svg" width="' + $width + '" height="' + $height + '" viewBox="0 0 30 30"><style>.st0{fill: ' + $color + ';}</style><path id="Path_18" class="st0" d="M15,5C9.5,5,5,9.5,5,15c0,5.5,4.5,10,10,10c5.5,0,10-4.5,10-10C25,9.5,20.5,5,15,5z M20.9,15c0-1.1-0.1-2.2-0.3-3.3h2.2c0.4,1.1,0.7,2.2,0.7,3.3c0,1.1-0.2,2.3-0.7,3.3h-2.2C20.8,17.3,20.9,16.1,20.9,15L20.9,15z M15.7,23.3v-3.5h2.9C17.9,21.7,16.9,23,15.7,23.3L15.7,23.3z M15.7,10.2V6.6c1.1,0.4,2.2,1.7,2.9,3.5H15.7z M18.9,7.4c1.2,0.7,2.3,1.6,3.1,2.8h-1.8C20,9.2,19.5,8.3,18.9,7.4L18.9,7.4z M15.7,18.3v-6.7h3.4c0.2,1.1,0.4,2.2,0.4,3.4c0,1.1-0.1,2.2-0.4,3.3L15.7,18.3z M18.8,22.6c0.6-0.9,1.1-1.8,1.4-2.8H22C21.2,21,20.1,22,18.8,22.6L18.8,22.6z M14.3,6.6v3.5h-2.9C12.1,8.3,13.1,7,14.3,6.6L14.3,6.6z M14.3,19.8v3.5c-1.1-0.4-2.2-1.7-2.9-3.5H14.3z M8,10.2C8.8,9,9.9,8,11.2,7.4c-0.6,0.9-1.1,1.8-1.4,2.8H8z M9.7,19.8c0.3,1,0.7,1.9,1.3,2.8C9.8,21.9,8.8,21,8,19.8H9.7z M14.3,11.7v6.6h-3.4c-0.2-1.1-0.4-2.2-0.4-3.3c0-1.1,0.1-2.2,0.4-3.3L14.3,11.7z M9.4,18.3H7.2c-0.4-1.1-0.7-2.2-0.7-3.3c0-1.1,0.2-2.3,0.7-3.3h2.2c-0.2,1.1-0.3,2.2-0.3,3.3C9.1,16.1,9.2,17.2,9.4,18.3L9.4,18.3z"/></svg>');
    } @else if $type == account {
        background-image: svg-uri('<svg xmlns="http://www.w3.org/2000/svg" width="' + $width + '" height="' + $height + '" viewBox="0 0 40 40"><style>.st0 {fill: ' + $color + ';}</style><path class="st0" d="M13,26.2c0-3.1,3.1-5.7,7-5.7s7,2.6,7,5.7c0,1.2-3.5,1.8-7,1.8S13,27.4,13,26.2z M20,19.7c2.1,0,3.8-1.7,3.8-3.8S22.1,12,20,12s-3.8,1.7-3.8,3.8c0,1,0.4,2,1.1,2.7C18,19.3,19,19.7,20,19.7z"/></svg>');
    } @else if $type == arrowthin {
        background-image: svg-uri('<svg xmlns="http://www.w3.org/2000/svg"  width="' + $width + '" height="' + $height + '" viewBox="0 0 11.623 5.706"><path id="Path_349" data-name="Path 349" d="M308.611,471.085a.912.912,0,0,0-.1-.341l-.081-.116a.712.712,0,0,0-.992-.059l-4.631,3.867-4.637-3.872a.72.72,0,0,0-.924,1.1l5.1,4.275a.724.724,0,0,0,.45.158.756.756,0,0,0,.467-.166l5.042-4.212a.7.7,0,0,0,.31-.62Z" transform="translate(-296.991 -470.395)" fill="' + $color + '"/></svg>');
    } @else if $type == home {
        background-image: svg-uri('<svg xmlns="http://www.w3.org/2000/svg" width="' + $width + '" height="' + $height + '" viewBox="0 0 10 10"><path d="M12.8,8.332,8.365,3.491a.463.463,0,0,0-.75,0l-4.5,4.968a.625.625,0,0,0,.437,1.019h1v3.439a.4.4,0,0,0,.375.382H6.554a.407.407,0,0,0,.375-.382V11.071a.3.3,0,0,1,.312-.318H8.99a.29.29,0,0,1,.25.318v1.847a.364.364,0,0,0,.375.382h1.5c.375,0,.437-.191.437-.382V9.478h.812A.679.679,0,0,0,12.8,8.332Z" transform="translate(-2.979 -3.3)" fill="' + $color + '"/></svg>');
    } @else if $type == specwidth {
        background-image: svg-uri('<svg width="' + $width + '" height="' + $height + '"  fill="' + $color + '" viewbox="0 0 100 100" xmlns:x="http://ns.adobe.com/Extensibility/1.0/" xmlns:i="http://ns.adobe.com/AdobeIllustrator/10.0/" xmlns:graph="http://ns.adobe.com/Graphs/1.0/" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" x="0px" y="0px" viewBox="0 0 64 64" enable-background="new 0 0 64 64" xml:space="preserve"><metadata><sfw xmlns="http://ns.adobe.com/SaveForWeb/1.0/"><slices></slices><sliceSourceBounds height="16384" width="16389" x="-8165" y="-8160" bottomLeftOrigin="true"></sliceSourceBounds></sfw></metadata><g><g><path d="M49.414,30.586l-4-3.999c-0.781-0.78-2.047-0.781-2.828,0s-0.781,2.047,0,2.829l0.585,0.584h-22.34l0.585-0.585    c0.781-0.781,0.781-2.047,0-2.828c-0.78-0.781-2.047-0.781-2.828,0l-4,4c-0.375,0.375-0.586,0.884-0.586,1.414    c0,0.531,0.211,1.039,0.586,1.414l4,3.999C18.979,37.805,19.491,38,20.002,38s1.024-0.195,1.415-0.586    c0.781-0.781,0.781-2.048,0-2.828l-0.585-0.585h22.339l-0.585,0.585c-0.781,0.78-0.781,2.047,0,2.828    C42.977,37.805,43.488,38,44,38s1.023-0.195,1.414-0.586l4-3.999C49.789,33.04,50,32.532,50,32.001S49.789,30.962,49.414,30.586z"></path><path d="M32,6C17.664,6,6,17.664,6,32c0,14.337,11.664,26,26,26c14.337,0,26-11.663,26-26C58,17.664,46.337,6,32,6z M32,54 c-12.131,0-22-9.869-22-22s9.869-22,22-22s22,9.869,22,22S44.131,54,32,54z"></path></g></g></svg>');
    } @else if $type == date {
        background-image: svg-uri('<svg xmlns="http://www.w3.org/2000/svg" width="' + $width + '" height="' + $height + '" viewBox="0 0 512 512"><style>.st0{fill: ' + $color + ';}</style><path class="st0" d="M481.28,38H431v71.76c0,23.47-18,42.24-40.6,42.24H377.6C355,152,337,133.24,337,109.76V38H183v71.76c0,23.47-18.34,42.24-40.92,42.24h-12.8C106.7,152,88,133.24,88,109.76V38H30.72C13.82,38,0,52.75,0,70.31V480.07C0,497.63,13.82,512,30.72,512H481.28c16.9,0,30.72-14.37,30.72-31.93V70.31C512,52.75,498.18,38,481.28,38ZM469,445.5A22.57,22.57,0,0,1,446.5,468H65.5A22.57,22.57,0,0,1,43,445.5v-230A22.57,22.57,0,0,1,65.5,193h381A22.57,22.57,0,0,1,469,215.5v230Z"></path><path class="st0" d="M129.28,134h12.8c12.7,0,22.92-11,22.92-24.24V23.95C165,10.74,154.78,0,142.08,0h-12.8C116.58,0,106,10.74,106,23.95v85.82C106,123,116.58,134,129.28,134Z"></path><path class="st0" d="M377.6,134h12.8c12.7,0,22.6-11,22.6-24.24V23.94C413,10.74,403.1,0,390.4,0H377.6C364.9,0,355,10.74,355,23.94v85.82C355,123,364.9,134,377.6,134Z"></path><rect class="st0" x="80" y="237" width="90" height="76"></rect><rect class="st0" x="80" y="348" width="90" height="76"></rect><rect class="st0" x="211" y="237" width="90" height="76"></rect><rect class="st0" x="211" y="348" width="90" height="76"></rect><rect class="st0" x="345" y="237" width="90" height="76"></rect><rect class="st0" x="345" y="348" width="90" height="76"></rect></svg>');
    } @else if $type == time {
        background-image: svg-uri('<svg xmlns="http://www.w3.org/2000/svg" width="' + $width + '" height="' + $height + '" viewBox="0 0 24 24"><style>.st0{fill: ' + $color + ';}</style><g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><path class="st0" d="M12,23 C5.92486775,23 1,18.0751322 1,12 C1,5.92486775 5.92486775,1 12,1 C18.0751322,1 23,5.92486775 23,12 C23,18.0751322 18.0751322,23 12,23 Z M12,21 C16.9705627,21 21,16.9705627 21,12 C21,7.02943725 16.9705627,3 12,3 C7.02943725,3 3,7.02943725 3,12 C3,16.9705627 7.02943725,21 12,21 Z M12,12.381966 L15.4472136,14.1055728 C15.9411921,14.3525621 16.1414164,14.9532351 15.8944272,15.4472136 C15.6474379,15.9411921 15.0467649,16.1414164 14.5527864,15.8944272 L10.5527864,13.8944272 C10.2140024,13.7250352 10,13.3787721 10,13 L10,7 C10,6.44771525 10.4477153,6 11,6 C11.5522847,6 12,6.44771525 12,7 L12,12.381966 Z" fill="#000000" fill-rule="nonzero"></path></g></svg>');
    } @else if $type == marker {
        background-image: svg-uri('<svg xmlns="http://www.w3.org/2000/svg"  width="' + $width + '" height="' + $height + '" viewBox="0 0 68 90"><style>.st0{fill: ' + $color + ';}</style><g><g><path class="st0" d="M36.2,0H34h-2.3C12.1,1-4.7,21.5,1.2,40.6C4.4,49.8,9.3,58,14.8,65.8c1.9,2.8,4,5.5,6,8.1c1.8,2.4,3.7,4.7,5.5,7c2.4,3,4.8,6,7.2,9.1H34h0.5c2.3-3.1,4.7-6.1,7.1-9.2c1.9-2.3,3.7-4.7,5.5-7c2.1-2.7,4.1-5.3,6-8.1c5.8-8.1,10.8-16.7,14-26.2C72,20.5,55.4,1,36.2,0z M34,55.4c-0.1,0-0.1,0-0.2,0c-12.6,0-22.9-10.2-22.9-22.7C10.9,20.2,21.2,10,33.8,10c0.1,0,0.1,0,0.2,0c12.5,0.1,22.7,10.3,22.7,22.7C56.7,45.1,46.5,55.3,34,55.4z"/></g></g></svg>');
    } @else if $type == playbtn {
        background-image: svg-uri('<svg xmlns="http://www.w3.org/2000/svg" width="' + $width + '" height="' + $height + '" viewBox="0 0 100 100"><style>.st0{fill: ' + $color + ';}</style><path class="st0" d="M79.266,37.885L34.723,12.168C25.397,6.784,13.74,13.514,13.74,24.283v51.434c0,10.768,11.657,17.499,20.983,12.115  l44.543-25.717C88.592,56.73,88.592,43.27,79.266,37.885z"></path></svg>');
    } @else if $type == link {
        background-image: svg-uri('<svg xmlns="http://www.w3.org/2000/svg" width="' + $width + '" height="' + $height + '" viewBox="0 0 40 40"><style>.st0{fill: ' + $color + ';}</style><g><g><path class="st0" d="M21.1,22.9c-1.1,0-2.1-0.4-2.8-1.2c-0.3-0.3-0.3-0.9,0-1.3c0.3-0.3,0.9-0.3,1.3,0c0.4,0.4,1,0.7,1.6,0.7c0.6,0,1.2-0.2,1.6-0.7l2.4-2.4c0.9-0.9,0.9-2.3,0-3.2c-0.9-0.9-2.3-0.9-3.2,0l-1,1c-0.3,0.3-0.9,0.3-1.3,0c-0.3-0.3-0.3-0.9,0-1.3l1-1c1.6-1.6,4.1-1.6,5.7,0c1.6,1.6,1.6,4.1,0,5.7L24,21.7C23.2,22.5,22.2,22.9,21.1,22.9z"/></g><g><path class="st0" d="M16.5,27.5c-1.1,0-2.1-0.4-2.8-1.2c-1.6-1.6-1.6-4.1,0-5.7l2.4-2.4c1.6-1.6,4.1-1.6,5.7,0c0.3,0.3,0.3,0.9,0,1.3c-0.3,0.3-0.9,0.3-1.3,0c-0.9-0.9-2.3-0.9-3.2,0l-2.4,2.4c-0.9,0.9-0.9,2.3,0,3.2c0.4,0.4,1,0.7,1.6,0.7c0.6,0,1.2-0.2,1.6-0.7l1-1c0.3-0.3,0.9-0.3,1.3,0s0.3,0.9,0,1.3l-1,1C18.6,27.1,17.6,27.5,16.5,27.5z"/></g></g></svg>');
    } @else if $type == polygon {
        background-image: svg-uri('<svg xmlns="http://www.w3.org/2000/svg" width="' + $width + '" height="' + $height + '" viewBox="0 0 60 69"><style>.st0{fill:none;}.st1{fill:' + $color + ';}</style><g transform="translate(225.818 495.359) rotate(-90)"><path class="st0" d="M477.5-224.8h-33.4l-16.7,29l16.7,29h33.4l16.7-29L477.5-224.8z"/><path class="st1" d="M444.2-224.8l-16.7,29l16.7,29h33.4l16.7-29l-16.7-29H444.2 M443.6-225.8h0.6h33.4h0.6l0.3,0.5l16.7,29l0.3,0.5l-0.3,0.5l-16.7,29l-0.3,0.5h-0.6h-33.4h-0.6l-0.3-0.5l-16.7-29l-0.3-0.5l0.3-0.5l16.7-29L443.6-225.8z"/></g></svg>');
    }
    background-repeat: no-repeat;
    background-position: center center;
}

// Animations and keyframes
@mixin keyframes($animation-name) {
    @keyframes #{$animation-name} {
        @content;
    }

    @keyframes #{$animation-name} {
        @content;
    }

    @keyframes #{$animation-name} {
        @content;
    }

    @keyframes #{$animation-name} {
        @content;
    }

    @keyframes #{$animation-name} {
        @content;
    }
}

// Greyscale filter
@mixin grayscale($grayscale) {
    filter: #{ "grayscale(" + $grayscale + ")" };
}

// Opacity
@mixin opacity($opacity) {
    opacity: $opacity;
    filter: alpha(opacity = $opacity * 100);
}

// SVG icons
@function svg-uri($svg) {
    $encoded: "";
    $slice: 2000;
    $index: 0;
    $loops: ceil(str-length($svg) / $slice);

    @for $i from 1 through $loops {
        $chunk: str-slice($svg, $index, $index + $slice - 1);
        $chunk: str-replace($chunk, '"', "'");
        $chunk: str-replace($chunk, "<", "%3C");
        $chunk: str-replace($chunk, ">", "%3E");
        $chunk: str-replace($chunk, "&", "%26");
        $chunk: str-replace($chunk, "#", "%23");
        $chunk: str-replace($chunk, "{", "%7B");
        $chunk: str-replace($chunk, "}", "%7D");
        $encoded: #{$encoded}#{$chunk};
        $index: $index + $slice;
    }

    @return url("data:image/svg+xml;charset=utf8,#{$encoded}");
}

@function str-replace($string, $search, $replace: "") {
    $index: str-index($string, $search);

    @if $index {
        @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
    }

    @return $string;
}
