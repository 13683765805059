.header {
    position: sticky;
    top: 0;
    flex-shrink: 0;
    background-color: $color-black;
    margin: 0 0 40px;
    transition: all .3s;
    z-index: 999;

    &__search {
        position: relative;
        margin: -68px 0 0;
        padding: 10px 15px;
        background-color: $color-black;
        transition: all .3s ease-in-out;

        @include media-breakpoint-down(md) {
            padding: 10px;
        }

        .form-control {
            background-color: $color-white;
            border-color: $color-white;
        }

        .form-search {
            margin: 0 auto;
        }

        .search-suggestions {
            position: absolute;
            top: 60px;
            left: 15px;
            right: 15px;
            display: none;
            padding: 0;
            margin: 0;
            border-radius: 24px;
            background-color: $color-white;
            z-index: 1;

            @include media-breakpoint-down(md) {
                text-align: left;
            }

            li {
                border-bottom: 1px solid $color-black;

                &:last-child {
                    border-bottom: 0;
                }

                a {
                    display: block;
                    padding: 8px 15px;
                }
            }
        }
    }

    &__content {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        padding: 15px 40px;

        @include media-breakpoint-down(md) {
            padding: 10px 15px;
            align-items: normal;
        }
    }

    .logo {
        display: block;
        height: 50px;
        order: 2;
        margin: 0 auto 0 0;
        transition: all .3s;

        @include media-breakpoint-down(md) {
            height: 40px;
            margin: 0 auto;
        }

        img {
            width: auto;
            height: 100%;
            max-height: 100%;
        }
    }

    .header-toolbar {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
        flex: 0;
        order: 3;

        @include media-breakpoint-down(md) {
            flex: 1;
            flex-direction: row;
        }

        .navigation {
            &--header {
                flex-basis: 100%;
            }
        }
    }

    .navigation {
        &--header {
            position: relative;
            display: flex;
            flex-flow: wrap column;
            justify-content: flex-end;
            transition: all .3s;
            margin: 16px 0 4px;
            padding: 0 150px 0 0;
            order: 3;

            @include media-breakpoint-down(md) {
                position: fixed;
                flex-flow: column unset;
                justify-content: flex-start;
                padding: 0;
                margin: 0;
            }

            .primary,
            .secondary {
                transition: all .3s;
            }

            .btn {
                position: absolute;
                right: 0;
                top: 50%;
                width: 140px;
                font-size: 11px;
                margin: -20px 0 0;
                z-index: 1;

                @include media-breakpoint-down(md) {
                    position: static;
                    width: auto;
                    order: 3;
                    margin: 0 15px 20px;
                }
            }
        }
    }

    .search-toggle {
        @include icon(search, $color-white, 20px, 20px);
        display: block;
        width: 40px;
        height: 40px;
        padding: 0;
        margin: 0 0 0 10px;
        font-size: 0;

        &:hover,
        &:focus {
            @include icon(search, $color-red, 20px, 20px);
        }

        &.active {
            @include icon(close, $color-red, 20px, 20px);

            &:hover,
            &:focus {
                @include icon(close, $color-red, 20px, 20px);
            }
        }
    }

    .language-toggle {
        @include icon(globe, $color-white, 30px, 30px);
        display: block;
        width: 40px;
        height: 40px;
        padding: 0;
        margin: 0 0 0 10px;
        font-size: 0;

        &:hover,
        &:focus {
            @include icon(globe, $color-red, 30px, 30px);
        }
    }

    .nav-toggle {
        @include icon(close, transparent, 20px, 20px);
        display: none;
        width: 40px;
        height: 40px;
        padding: 0;
        margin: 0 0 0 10px;
        transition: all .3s ease-in-out;
        order: 4;
        z-index: 99999;

        @include media-breakpoint-down(md) {
            display: block;
        }

        &:hover,
        &:focus {
            .bar,
            &::before,
            &::after {
                background-color: $color-white;
            }
        }

        .bar,
        &::before,
        &::after {
            display: block;
            width: 30px;
            height: 4px;
            margin: 6px auto;
            content: "";
            background-color: $color-white;
            transition: all .3s;
            border-radius: 2px;
            backface-visibility: hidden;
        }

        &.active {
            @include icon(close, $color-white, 20px, 20px);

            &:hover,
            &:focus {
                @include icon(close, $color-white, 20px, 20px);
            }

            &::before {
                transform: rotate(45deg) translate(7px, 7px);
            }

            &::after {
                transform: rotate(-45deg) translate(7px, -7px);
            }

            .bar,
            &::before,
            &::after {
                opacity: 0;
            }
        }
    }

    .btn {
        &--description {
            color: $color-white;
            margin: 0 0 0 10px;

            &:hover,
            &:focus {
                color: $color-red;
            }

            &.btn {
                &--phone {
                    &::before {
                        @include icon(phone, $color-white, 40px, 40px);
                    }

                    &:hover,
                    &:focus {
                        &::before {
                            @include icon(phone, $color-red, 40px, 40px);
                        }
                    }
                }

                &--account {
                    &::before {
                        @include icon(account, $color-white, 40px, 40px);
                    }

                    &:hover,
                    &:focus {
                        &::before {
                            @include icon(account, $color-red, 40px, 40px);
                        }
                    }
                }
            }
        }

        + .btn {
            margin: 0 0 0 10px;
        }
    }

    + .main {
        .block {
            &--hero,
            &--slider,
            &--main-slider,
            &--banner,
            &--ghostframe {
                &:first-child {
                    margin-top: -$default-margin;
                }
            }
        }
    }
}

.primary-search-active {
    .header {
        &__search {
            margin: 0;
        }
    }
}

// Landingspage 2

.landingspage-2 {
    .header {
        background-color: $color-blue;

        &__content {
            padding: 25px 40px;

            @include media-breakpoint-down(md) {
                padding: 10px 15px;
            }
        }

        .logo {
            height: 70px;

            @include media-breakpoint-down(md) {
                height: 40px;
            }
        }

        .navigation {
            &--header {
                margin: 5px 0;

                @include media-breakpoint-down(md) {
                    margin: 0;
                }

                .btn {
                    color: $color-white;
                    border-color: $color-white;
                    background-color: transparent;

                    &:hover,
                    &:focus {
                        color: $color-blue;
                        background-color: $color-white;
                    }
                }

                .secondary.anchors {
                    @include media-breakpoint-down(md) {
                        display: none;
                    }
                }
            }
        }

        .btn {
            &--description {
                color: $color-white;

                &:hover,
                &:focus {
                    color: $color-blue;
                }

                &.btn {
                    &--phone {
                        &:hover,
                        &:focus {
                            &::before {
                                @include icon(phone, $color-blue, 40px, 40px);
                            }
                        }
                    }

                    &--account {
                        &:hover,
                        &:focus {
                            &::before {
                                @include icon(account, $color-blue, 40px, 40px);
                            }
                        }
                    }
                }
            }
        }
    }

    &.transparent {
        &::before {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 120px;
            content: "";
            background: $color-blue;
            z-index: 1;

            @include media-breakpoint-down(md) {
                height: 60px;
            }
        }

        .header {
            background-color: transparent;

            + .main {
                .block {
                    &:first-child {
                        margin-top: -(120px + $default-margin);

                        @include media-breakpoint-down(md) {
                            margin-top: -(60px + $default-margin);
                        }
                    }
                }
            }
        }
    }
}
