.footer {
    position: relative;
    flex-shrink: 0;
    color: $color-white;
    background-color: $color-extra-black;

    @include media-breakpoint-down(md) {
        flex-direction: column;
        align-items: center;
    }

    &__top {
        display: flex;
        flex-wrap: wrap;
        color: $color-white;
        font-size: 14px;
        line-height: 26px;
        padding: 40px 0 0;

        @include media-breakpoint-down(sm) {
            display: none;
        }

        .is-h3 {
            color: $color-white;
            font-size: 17px;
            line-height: 26px;
            text-transform: none;

            + .list {
                margin-top: -30px;
            }
        }

        .list {
            li {
                a {
                    color: $color-white;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 26px;
                }
            }
        }
    }

    &__bottom {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        color: $color-white;
        font-size: 14px;
        line-height: 26px;
        padding: 20px 15px 0;

        @include media-breakpoint-down(md) {
            flex-direction: column;
            align-items: center;
        }
    }

    .copyright {
        display: inline-block;
        color: transparentize($color-white, .5);
        font-size: 14px;
        line-height: 24px;
        margin: 0 0 20px auto;

        @include media-breakpoint-down(md) {
            margin: 0 0 20px;
        }
    }

    .navigation {
        display: inline-block;
        margin: 0 0 20px;
    }

    .social-share {
        margin: -4px 0 10px auto;

        @include media-breakpoint-down(md) {
            display: flex;
            justify-content: center;
            width: 100%;
        }
    }
}

.footer-doormat {
    @include make-row;
    margin: 0 -15px;

    &__item {
        @include make-col(4);
        padding: 0 15px;

        @include media-breakpoint-down(md) {
            @include make-col(8);
        }

        .list {
            column-count: 2;
        }

        &:first-of-type,
        &:last-of-type {
            @include make-col(2);

            @include media-breakpoint-down(md) {
                @include make-col(4);
            }

            .list {
                column-count: 1;
            }
        }
    }
}

// Landingspage

.landingspage {
    .footer {
        display: flex;
        flex-wrap: wrap;
        padding: 20px 15px 0;

        .copyright {
            margin: 0 0 20px;
        }
    }
}

// Landingspage 2

.landingspage-2 {
    .footer {
        background-color: $color-blue-dark;
    }
}
