// Plyr override

// scss-lint:disable ImportantRule
.plyr {
    $self: &;

    &--video {
        background-color: transparent;

        &:hover {
            #{ $self }__control {
                &--overlaid {
                    background-color: $color-red;
                }
            }
        }

        #{ $self }__control {
            &:hover {
                background-color: $color-red;
            }

            &--overlaid {
                background-color: rgba($color-red, .8);
            }

            &#{ $self }__tab-focus {
                background-color: rgba($color-red, .8);
                box-shadow: none;
            }
        }

        #{ $self }__controls {
            opacity: 0;
            transform: translateY(100%);
        }
    }

    &--playing {
        #{ $self }__controls {
            opacity: 1;
            transform: none;
        }
    }

    &--full-ui {
        input[type="range"] {
            color: $color-red;
        }
    }

    &__video-wrapper {
        text-align: center;
        height: 100%;

        video {
            width: auto;
            height: 100%;
            margin: 0 auto;
        }
    }
}

// scss-lint:enable ImportantRule
