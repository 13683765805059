// Highlights block

.block {
    &--highlights {
        margin: 0;
    }
}

.highlights {
    display: flex;
    flex-direction: column;
    margin: 0 0 ($default-block-margin - $default-margin);

    &__title {
        text-align: center;
    }

    &__buttons {
        text-align: center;
    }
}

.highlight {
    $self: &;
    position: relative;
    display: flex;
    flex-direction: column;
    margin: 0 0 $default-margin;

    &__image {
        width: 83.3333%;
        margin: 0 auto 0 0;
        border-radius: 5px;
        overflow: hidden;

        @include media-breakpoint-down(md) {
            width: 90%;
        }

        + #{ $self }__content {
            margin-top: -100px;

            @include media-breakpoint-down(sm) {
                margin-top: -50px;
            }
        }

        .list {
            position: absolute;
            left: 0;
            bottom: 0;
            right: 0;
            padding: 5px;
            margin: 0;
            background-color: transparentize($color-black, .4);

            li {
                color: $color-white;

                .icon {
                    &--date {
                        @include icon(date, $color-white, 20px, 20px);
                    }

                    &--time {
                        @include icon(time, $color-white, 20px, 20px);
                    }

                    &--marker {
                        @include icon(marker, $color-white, 20px, 20px);
                    }

                    &--spec-width {
                        @include icon(specwidth, $color-white, 26px, 26px);
                    }
                }
            }
        }
    }

    &__content {
        position: relative;
        flex: 1;
        width: 83.3333%;
        margin: 0 0 0 auto;
        padding: 20px 30px 0;
        background-color: $color-white;
        border-radius: 5px;
        box-shadow: 0 0 20px 0 transparentize($color-extra-black, .8);
        overflow: hidden;

        @include media-breakpoint-down(md) {
            width: 90%;
        }

        .is-h4 {
            a {
                color: $color-red;
                text-decoration: none;

                &:hover,
                &:focus {
                    color: $color-red;
                    text-decoration: none;
                }
            }

            + .richtext {
                margin-top: -$default-margin;
            }

            + .list {
                margin-top: -($default-margin / 2);
            }
        }

        .read-more {
            position: absolute;
            right: -30px;
            bottom: -30px;
            display: flex;
            width: 142px;
            height: 142px;
            align-items: center;
            text-align: center;
            color: $color-white;
            font-size: 14px;
            line-height: 16px;
            font-weight: 700;
            padding: 20px 30px;
            z-index: 0;
            border: 4px solid $color-red;
            border-radius: 50%;

            &::after {
                position: absolute;
                top: 50%;
                left: 50%;
                display: block;
                width: 124px;
                height: 124px;
                margin: -62px 0 0 -62px;
                content: "";
                border-radius: 50%;
                background-color: $color-red;
                z-index: -1;
            }

            &:hover,
            &:focus {
                color: $color-white;
            }
        }

        &--read-more {
            min-height: 125px;
            padding-right: 125px;
        }
    }

    &--simple {
        flex-direction: column;
        background-color: $color-white;
        border-radius: 5px;
        overflow: hidden;
        box-shadow: 0 0 20px 0 transparentize($color-extra-black, .8);

        #{ $self }__image {
            position: relative;
            width: 100%;
            margin: 0;
            border-radius: 0;

            a {
                display: block;
                overflow: hidden;

                img {
                    transform: scale(1);
                    transition: transform .3s ease-in-out;
                }

                &:hover,
                &:focus {
                    img {
                        transform: scale(1.1);
                    }
                }
            }
        }

        #{ $self }__content {
            flex: 1;
            width: 100%;
            padding: 30px 30px 0;
            margin: 0;
            border-radius: 0;
            box-shadow: none;

            .list {
                li {
                    a {
                        &:hover,
                        &:focus {
                            color: $color-white;
                            text-decoration: none;
                        }
                    }
                }

                &--tags {
                    margin-top: auto;
                }
            }

            .is-h4 {
                font-weight: 700;

                a {
                    &:hover,
                    &:focus {
                        text-decoration: none;
                    }
                }
            }
        }

        #{ $self }__actions {
            padding: 0 30px;
        }
    }
}

.highlights-overview {
    $self: &;

    @include make-row;
    margin: 0 -15px;

    &__item {
        @include make-col(6);
        padding: 0 15px;
        margin: 0 0 $default-margin;

        @include media-breakpoint-down(sm) {
            @include make-col(12);
        }

        &[data-category] {
            display: inherit;

            &.hidden {
                display: none;
            }
        }

        .highlight {
            height: 100%;
            margin: 0;
        }
    }

    &--three-columns {
        #{ $self }__item {
            @include make-col(4);

            @include media-breakpoint-down(md) {
                @include make-col(6);
            }

            @include media-breakpoint-down(sm) {
                @include make-col(12);
            }
        }
    }

    &--four-columns {
        #{ $self }__item {
            @include make-col(3);

            @include media-breakpoint-down(md) {
                @include make-col(6);
            }

            @include media-breakpoint-down(sm) {
                @include make-col(12);
            }
        }
    }

    &--compact {
        margin: 0 0 40px;

        #{ $self }__item {
            padding: 0 1px 0 0;
            margin: 0 0 1px;

            .highlight {
                box-shadow: none;
                background-color: $color-gray-light;
                text-align: center;
                border-radius: 0;

                &__content {
                    background-color: transparent;

                    a {
                        color: $color-black;

                        &:hover,
                        &:focus {
                            color: $color-red;
                        }
                    }

                    .list {
                        display: flex;
                        justify-content: center;
                        margin-bottom: 10px;
                    }
                }

                &__image {
                    padding: 0;

                    img {
                        width: 100%;
                        transition: all .3s;
                    }

                    &:hover,
                    &:focus {
                        img {
                            transform: scale(1.1);
                        }
                    }
                }
            }
        }
    }
}

// Landingspage 2

.landingspage-2 {
    .highlight {
        &__image {
            border-radius: 0;
        }

        &__content {
            border-radius: 0;

            .is-h4 {
                a {
                    color: $text-color;

                    &:hover,
                    &:focus {
                        color: $color-blue;
                    }
                }

                + .richtext,
                + .list {
                    margin-top: -($default-margin / 2);
                }
            }

            .read-more {
                border-color: $color-green;

                &::after {
                    background-color: $color-green;
                }
            }
        }

        &--simple {
            border-radius: 0;
        }
    }
}
