.breadcrumb {
    display: flex;
    padding: 10px 40px;
    margin: -$default-margin 0 $default-margin;
    border-radius: 0;
    background-color: transparent;
    z-index: 9;

    @include media-breakpoint-down(md) {
        padding: 10px 15px;
    }

    @include media-breakpoint-down(sm) {
        display: none;
    }

    &-list {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        padding: 0 15px;
        margin: 0;
        font-size: 13px;
        border-radius: 50px;
        background: rgba($color-extra-black, .4);
    }

    &-item {
        color: rgba($color-black, .5);
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        a {
            color: $color-white;
            font-size: 14px;
            line-height: normal;
            font-weight: 400;
        }

        &:first-of-type {
            a {
                @include icon(home, $color-white, 10px, 10px);
                position: relative;
                width: 10px;
                height: 10px;
                display: block;

                span {
                    display: block;
                    text-indent: -99999px;
                }
            }
        }

        &.active {
            a {
                color: $color-white;
                text-decoration: none;
            }
        }

        + .breadcrumb {
            &-item {
                padding-left: 5px;

                &::before {
                    color: $color-white;
                    padding-right: 5px;
                }
            }
        }
    }

    + .main {
        .block {
            &--hero,
            &--slider,
            &--main-slider,
            &--banner,
            &--ghostframe {
                &:first-child {
                    margin-top: -($default-margin + 48px);

                    @include media-breakpoint-down(sm) {
                        margin-top: -$default-margin;
                    }
                }
            }
        }
    }
}
