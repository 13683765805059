// Filter block.

.block {
    &--filter {
        margin: 0;
    }
}

.filter-block {
    margin: 0 0 75px;

    &--small {
        width: 83.3333%;
        margin: 0 auto $default-block-margin;

        @include media-breakpoint-down(md) {
            width: auto;
            margin: 0 0 $default-block-margin;
        }
    }

    .list {
        margin: 0;
    }

    .form-filter {
        margin: 0;
    }
}

.filter-buttons {
    @extend .btn-group-toggle;
    display: flex;
    flex-wrap: wrap;
    margin: 0 -5px;

    .btn {
        flex: 0 0 calc(25% - 10px);
        margin: 0 5px 10px;
        text-transform: initial;
        font-size: 15px;

        @include media-breakpoint-down(lg) {
            flex: 0 0 calc(33.3% - 10px);
        }

        @include media-breakpoint-down(md) {
            flex: 0 0 calc(50% - 10px);
        }

        @include media-breakpoint-down(sm) {
            flex: 0 0 100%;
            margin-right: 0;
        }

        &.active,
        &:hover,
        &:focus {
            color: $color-white;
            background-color: darken($color-red, 10%);
            border-color: darken($color-red, 10%);
        }
    }
}
