// Slider block

.block {
    &--slider {
        margin: 0 0 $default-block-margin;

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.slider {
    $self: &;
    display: block;
    position: relative;
    overflow: hidden;

    &.owl-carousel {
        .owl-dots {
            button {
                &.owl-dot {
                    width: 12px;
                    height: 12px;
                    border-color: $color-white;

                    &:hover,
                    &.active {
                        background-color: $color-white;
                    }
                }
            }
        }

        .owl-nav {
            button {
                &.owl-prev,
                &.owl-next {
                    @include icon(arrow, $color-white, 36px, 81px);
                    width: 81px;
                    height: 81px;

                    &:hover,
                    &:focus {
                        @include icon(arrow, $color-white, 36px, 81px);
                    }
                }
            }
        }
    }

    &__item {
        display: flex;
        flex: 0 0 100%;

        + #{ $self }__item {
            display: none;
        }
    }
}

// scss-lint:disable ImportantRule
.slider-item {
    position: relative;
    width: 100%;

    @include media-breakpoint-down(sm) {
        height: calc(100vh - 59px);

        &__image {
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
            overflow: hidden;

            img {
                position: absolute;
                top: 50%;
                left: 50%;
                min-height: 100%;
                min-width: 100%;
                width: auto !important;
                height: auto;
                max-width: none;
                transform: translate(-50%, -50%);
            }
        }
    }

    &__image {
        position: relative;
        display: block;

        &--video {
            &:hover {
                &::after {
                    @include icon(play, $color-white, 18px, 18px);
                    background-color: $color-red;
                }
            }

            &::after {
                @include icon(play, $color-white, 18px, 18px);
                position: absolute;
                top: 50%;
                left: 50%;
                display: block;
                width: 48px;
                height: 48px;
                padding: 15px;
                content: "";
                border-radius: 50%;
                background-color: rgba($color-red, .8);
                transform: translate(-50%, -50%);
                z-index: 7;

                @include media-breakpoint-down(md) {
                    width: 60px;
                    height: 60px;
                }
            }
        }
    }

    &__content {
        position: absolute;
        left: 50%;
        bottom: 65px;
        display: block;
        width: 300px;
        margin: 0 0 0 -150px;
        text-align: center;
    }
}

// scss-lint:enable ImportantRule
