// Hero block

.block {
    &--hero {
        margin: 0 0 $default-block-margin;

        @include media-breakpoint-down(sm) {
            margin: 0 0 ($default-block-margin - $default-margin);
        }

        &:last-child {
            margin-bottom: 0;

            @include media-breakpoint-down(sm) {
                margin: 0 0 ($default-block-margin - $default-margin);
            }
        }

        + [data-ajax-self-replace] {
            .block {
                &--filter {
                    &:first-child {
                        margin-top: -($default-block-margin + 108px);

                        @include media-breakpoint-down(sm) {
                            margin-top: 0;
                        }
                    }
                }
            }
        }
    }
}

.hero {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 500px;

    @include media-breakpoint-down(sm) {
        min-height: unset;
    }

    &__image {
        position: relative;
        width: 100%;
        height: 500px;
        overflow: hidden;

        @include media-breakpoint-down(sm) {
            height: auto;
            margin: 0 0 $default-margin;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;

            @include media-breakpoint-down(sm) {
                height: auto;
            }
        }
    }

    &__content {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        @include media-breakpoint-down(sm) {
            position: static;
            transform: none;
        }

        .is-h1 {
            color: $color-white;
            text-shadow: 0 3px 20px rgba($color-white, .4);
            text-align: center;

            @include media-breakpoint-down(sm) {
                color: $text-color;
                font-size: 42px;
                line-height: 52px;
                text-shadow: none;
            }
        }
    }
}
