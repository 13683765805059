*,
*::before,
*::after {
    box-sizing: border-box;
    text-rendering: optimizelegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-kerning: auto;
}

html,
body {
    font-family: $text-font;
    color: $text-color;
    font-weight: 400;
    font-size: $text-size;
    line-height: $line-height;
    min-height: 100%;
    -webkit-font-smoothing: antialiased;
    text-size-adjust: 100%;
    background: $color-white;
}

body {
    display: flex;
    flex-direction: column;

    &.scroll-disabled {
        overflow-y: hidden;
    }
}

// scss-lint:disable ImportantRule
html {
    height: 100%;

    &.primary-navigation-active {
        @include media-breakpoint-down(md) {
            overflow: hidden;

            .btn {
                &--to-top {
                    display: none !important;
                }
            }
        }
    }

    &.scroll-disabled {
        body {
            overflow-y: hidden;
        }
    }
}

// scss-lint:enable ImportantRule

img:not([src]) {
    visibility: hidden;
}

img[data-src],
img[data-srcset] {
    display: block;
    min-height: 1px;
}

::selection {
    background-color: $color-gray;
}

.search-highlight {
    background-color: $color-gray;
}

.date {
    font-weight: 300;
    font-size: 15px;
}

iframe {
    display: block;
    max-width: 100%;
    border: 0;
}

a,
.link {
    color: $color-red;
    outline: none;
    text-decoration: none;

    &:hover,
    &:focus {
        color: $text-color;
        outline: none;
        text-decoration: underline;
    }
}

a[href^="tel"] {
    color: inherit;
    text-decoration: none;
}

p,
blockquote,
ul,
ol,
h1,
h2,
h3,
h4,
h5,
h6,
form,
table,
section,
aside,
article,
dl,
hr {
    margin: 0 0 $default-margin;
}

table {
    width: 100%;
    border-collapse: collapse;
}

ul,
ol {
    padding: 0;
    list-style-type: none;
}

img {
    @extend .img-fluid;
}

dl {
    display: flex;
    flex-wrap: wrap;

    dt,
    dd {
        flex: 0 0 50%;
        max-width: 50%;
        padding: 5px 8px;
        margin: 0;
        border-bottom: 1px solid $color-black;

        &:last-of-type {
            border-bottom: 0;
        }
    }

    dt {
        font-weight: 400;
    }

    dd {
        border-left: 1px solid $color-black;
    }
}

// scss-lint:disable ImportantRule
select {
    option {
        color: initial !important;
    }
}

// scss-lint:enable ImportantRule

[data-animate] {
    &.is-loaded {
        transition: all 1s ease;
        transform: translateY(100px);
        visibility: hidden;
        opacity: 0;

        &.is-visible {
            transform: translateY(0);
            visibility: visible;
            opacity: 1;
        }
    }
}

.info {
    display: block;
    font-style: italic;
    margin: 0 0 $default-margin;
}

.video-wrapper {
    text-align: center;
    margin: 0 0 $default-margin;

    &__inner {
        position: relative;
        display: block;
        padding: 25px 0 56.25%;
        height: 0;

        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
}

.icon {
    display: inline-block;
    height: 40px;
    width: 40px;
}

section {
    &.main {
        flex: 1 0 auto;
        margin: 0;
    }
}

.wrapper {
    @extend .container;
    @include clearfix;
}

.visible-tablet {
    display: none;

    @include media-breakpoint-down(md) {
        display: block;
    }
}

.hidden-tablet {
    @include media-breakpoint-down(md) {
        display: none;
    }
}

.visible-mobile {
    display: none;

    @include media-breakpoint-down(xs) {
        display: block;
    }
}

.hidden-mobile {
    @include media-breakpoint-down(xs) {
        display: none;
    }
}

// scss-lint:disable IdSelector

// scss-lint:disable ImportantRule
#hubspot-messages-iframe-container {
    z-index: 1 !important;

    &.widget-align-right {
        bottom: 100px !important;

        @include media-breakpoint-down(sm) {
            bottom: 60px !important;
        }
    }
}

// scss-lint:enable ImportantRule

// scss-lint:enable IdSelector

// Landingspage 2

.landingspage-2 {
    font-family: $text-font2;

    a,
    .link {
        color: $color-blue;

        &:hover,
        &:focus {
            color: $text-color;
        }
    }
}
