// Stories block

.block {
    &--stories {
        margin: 0;
    }
}

.stories {
    display: flex;
    flex-direction: column;
    margin: 0 0 ($default-block-margin - $default-margin);

    &__title {
        text-align: center;
    }
}

.story {
    $self: &;

    @include make-row;
    margin: 0 -15px;

    + #{ $self } {
        margin-top: $default-block-margin - $default-margin;
    }

    &__image {
        @include make-col(5);
        padding: 0 15px;
        margin: 0 auto $default-margin;

        @include media-breakpoint-down(md) {
            @include make-col(6);
        }

        @include media-breakpoint-down(sm) {
            @include make-col(12);
        }

        img {
            border-radius: 5px;
            box-shadow: 0 0 20px rgba($color-extra-black, .2);
        }

        a {
            display: block;
            overflow: hidden;
            border-radius: 5px;
            box-shadow: 0 0 20px rgba($color-extra-black, .2);

            img {
                transform: scale(1);
                transition: transform .3s ease-in-out;
                border-radius: 0;
                box-shadow: none;
            }

            &:hover,
            &:focus {
                img {
                    transform: scale(1.1);
                }
            }
        }
    }

    &__content {
        @include make-col(7);
        padding: $default-margin 11% 0;
        margin: 0 0 0 auto;

        @include media-breakpoint-down(md) {
            @include make-col(5);
            padding: 0 15px;
        }

        @include media-breakpoint-down(sm) {
            @include make-col(12);
        }

        .is-h3 {
            text-transform: uppercase;

            a {
                color: $text-color;
                text-decoration: none;

                &:hover,
                &:focus {
                    color: $color-red;
                    text-decoration: none;
                }
            }

            + .richtext,
            + .list {
                margin-top: -$default-margin;
            }
        }

        .richtext {
            + .read-more {
                margin-top: -$default-margin;
            }
        }
    }

    &--large {
        #{ $self }__image {
            @include make-col(6);

            @include media-breakpoint-down(sm) {
                @include make-col(12);
            }

            img {
                box-shadow: none;
            }

            a {
                box-shadow: none;
            }
        }

        #{ $self }__content {
            @include make-col(6);
            padding: 0 15px;

            @include media-breakpoint-down(sm) {
                @include make-col(12);
            }
        }
    }

    &--reversed {
        #{ $self }__image {
            margin-left: auto;
            margin-right: 0;
            order: 2;

            @include media-breakpoint-down(sm) {
                order: 1;
            }
        }

        #{ $self }__content {
            margin-left: 0;
            margin-right: auto;
            order: 1;

            @include media-breakpoint-down(sm) {
                order: 2;
            }
        }
    }

    &--centered {
        #{ $self }__image {
            display: none;
        }

        #{ $self }__content {
            margin-left: auto;
            margin-right: auto;
        }
    }

    .video {
        position: relative;

        &::after {
            @include icon(play, $color-white, 18px, 18px);
            position: absolute;
            top: 50%;
            left: 50%;
            display: block;
            width: 48px;
            height: 48px;
            padding: 15px;
            margin: -24px 0 0 -24px;
            content: "";
            border-radius: 50%;
            background-color: rgba($color-red, .8);
            z-index: 7;

            @include media-breakpoint-down(md) {
                width: 60px;
                height: 60px;
                margin: -30px 0 0 -30px;
            }
        }

        &:hover,
        &:focus {
            &::after {
                @include icon(play, $color-white, 18px, 18px);
                background-color: $color-red;
            }
        }
    }
}

// Landingspage 2

.landingspage-2 {
    .story {
        &__image {
            img {
                border-radius: 0;
            }

            a {
                border-radius: 0;
            }
        }

        &__content {
            .is-h3 {
                a {
                    color: $text-color;

                    &:hover,
                    &:focus {
                        color: $color-blue;
                    }
                }

                + .richtext,
                + .list {
                    margin-top: -$default-margin;
                }
            }
        }

        .video {
            &::after {
                background-color: rgba($color-blue, .8);
            }

            &:hover,
            &:focus {
                &::after {
                    background-color: $color-blue;
                }
            }
        }
    }
}
