// Downloads block

.block {
    &--downloads {
        position: relative;
        padding: 70px 0 30px;
        margin: 0 0 $default-block-margin;
        background: $color-red no-repeat center center;
        background-size: cover;

        &:last-child {
            margin-bottom: 0;
        }

        &::after {
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            width: 100%;
            height: 100%;
            content: "";
            opacity: .63;
            background-color: $color-red;
            z-index: 0;
        }

        .downloads {
            position: relative;
            color: $color-white;
            z-index: 1;

            .is-h2 {
                color: $color-white;
            }

            .list {
                &--downloads {
                    li {
                        a {
                            color: $color-white;
                            border-color: $color-white;

                            &:hover,
                            &:focus {
                                color: $color-black;
                                border-color: darken($color-white, 10%);
                                background-color: darken($color-white, 10%);
                            }
                        }
                    }
                }
            }
        }
    }
}

.downloads {
    display: flex;
    flex-direction: column;

    &__title {
        text-align: center;
    }
}

// Landingspage 2

.landingspage-2 {
    .block {
        &--downloads {
            background-color: $color-blue;

            &::after {
                opacity: .85;
                background-color: $color-blue;
            }
        }
    }
}
