// Owl Carousel override

// scss-lint:disable ImportantRule
.owl-carousel {
    display: block !important;
    z-index: 0;

    .owl-stage {
        display: flex !important;
        flex-wrap: wrap !important;
    }

    .owl-item {
        display: flex !important;
        float: none !important;
        backface-visibility: hidden;
        transform: translateZ(0) scale(1, 1);

        &.fade-in {
            animation-name: owlFadeIn;
        }

        &.fade-up {
            animation-name: owlFadeUp;
        }

        &.fade-out {
            animation-name: owlFadeOut;

            &.active {
                animation: none;
                left: 0 !important;
            }
        }

        &.fade-down {
            animation-name: owlFadeDown;
        }
    }

    .owl-dots {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 30px;
        display: flex;
        height: 0;
        justify-content: center;
        z-index: 0;

        &.disabled {
            display: none;
        }

        button {
            &.owl-dot {
                display: inline-block;
                width: 15px;
                height: 15px;
                margin: 0 5px;
                background-color: transparent;
                border-radius: 50%;
                border: 2px solid $color-black;
                transform: translateY(-50%);

                &:hover,
                &.active {
                    background-color: $color-black;
                }
            }
        }
    }

    .owl-nav {
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        display: flex;
        height: 0;
        justify-content: space-between;
        z-index: 1;

        &.disabled {
            display: none;
        }

        button {
            &.owl-prev,
            &.owl-next {
                @include icon(arrow, $color-black, 25px, 25px);
                display: block;
                width: 50px;
                height: 50px;
                font-size: 0;
                background-color: transparent;
                transform: translateY(-50%);

                &:hover,
                &:focus {
                    @include icon(arrow, $color-black, 25px, 25px);
                }

                &.disabled {
                    opacity: 0;
                }
            }

            &.owl-next {
                transform: translateY(-50%) rotate(180deg);
            }
        }
    }
}

// scss-lint:enable ImportantRule

@include keyframes(owlFadeIn) {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@include keyframes(owlFadeOut) {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@include keyframes(owlFadeUp) {
    0% {
        opacity: 0;
        transform: translate3d(0, 100%, 0);
    }

    100% {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}

@include keyframes(owlFadeDown) {
    0% {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }

    100% {
        opacity: 0;
        transform: translate3d(0, -100%, 0);
    }
}
