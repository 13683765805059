// Double Slider block

.block {
    &--double-slider {
        margin: 0;
    }
}

// scss-lint:disable ImportantRule
.double-slider-item {
    $self: &;

    @include make-container-max-widths;
    @include make-row;
    padding: 360px 0 0;
    margin: 0 auto 10px;

    @include media-breakpoint-down(sm) {
        padding: 260px 15px 0;
    }

    &__image {
        position: absolute;
        top: 0;
        left: 50%;
        width: 100%;
        height: 540px;
        max-width: 1920px;
        overflow: hidden;
        transform: translateX(-50%);

        @include media-breakpoint-down(sm) {
            height: 328px;
        }

        img {
            position: absolute;
            top: 50%;
            left: 50%;
            min-height: 100%;
            min-width: 100%;
            max-width: none;
            width: auto !important;
            height: auto;
            transform: translate(-50%, -50%);
        }
    }

    &__content {
        @include make-col(6);
        position: relative;
        padding: 40px 40px 0;
        background-color: $color-white;
        box-shadow: 0 0 20px rgba($color-extra-black, .2);
        border-radius: 5px;

        @include media-breakpoint-down(md) {
            @include make-col(8);
        }

        @include media-breakpoint-down(sm) {
            @include make-col(12);
        }

        .is-h2 {
            font-size: 54px;
            line-height: 58px;
            text-transform: uppercase;

            @include media-breakpoint-down(sm) {
                font-size: 42px;
                line-height: 52px;
            }
        }

        .is-h6 {
            font-size: 14px;
            line-height: 24px;
            text-transform: uppercase;
            margin: 0;
        }
    }

    &--animate {
        &::after {
            position: absolute;
            top: 540px;
            left: 0;
            right: 0;
            bottom: 0;
            display: block;
            content: "";
            background-color: $color-white;
            z-index: 1;
        }

        #{ $self }__image {
            transition: transform 10s;
        }

        #{ $self }__content {
            z-index: 2;
        }
    }
}

// scss-lint:enable ImportantRule

// scss-lint:disable ImportantRule

// scss-lint:disable NestingDepth
.double-slider {
    $self: &;
    margin: 0 0 ($default-block-margin - 10px);

    &__item {
        position: relative;
        display: block;
        flex: 0 0 100%;
        max-width: 100%;

        &:nth-child(1) {
            ~ #{ $self }__item {
                display: none;
            }
        }
    }

    &.owl-carousel {
        .owl-stage {
            #{ $self }__item {
                flex: 0 0 100%;
                max-width: 100%;
            }
        }

        .owl-nav {
            top: 600px;
            left: 52%;
            width: 150px;

            @include media-breakpoint-down(md) {
                left: 65%;
            }

            @include media-breakpoint-down(sm) {
                width: 110px;
                top: 290px;
                right: 18%;
                left: auto;
            }

            @include media-breakpoint-down(xs) {
                right: 25px;
            }

            button {
                &.owl-prev,
                &.owl-next {
                    @include icon(arrow-big, $color-black, 22px, 34px);
                    width: 40px;
                    height: 40px;
                    opacity: .28;

                    @include media-breakpoint-down(sm) {
                        @include icon(arrow-big, $color-black, 12px, 18px);
                        width: 30px;
                        height: 30px;
                    }

                    &:hover,
                    &:focus {
                        opacity: 1;
                    }

                    &.disabled {
                        visibility: hidden;
                    }
                }
            }
        }

        .owl-counter {
            display: flex;
            flex: 1;
            height: 40px;
            justify-content: center;
            transform: translateY(-50%);

            @include media-breakpoint-down(sm) {
                height: 30px;
            }

            span {
                color: $color-black;
                font-size: 18px;
                line-height: 28px;
                font-weight: 900;
                margin: 0 5px;
                padding: 6px 0;

                @include media-breakpoint-down(sm) {
                    margin: 0 3px;
                    padding: 1px 0;
                }
            }
        }

        .owl-item {
            &.active {
                .double-slider-item {
                    &--animate {
                        .double-slider-item {
                            &__image {
                                img {
                                    animation: zoomeffect 10s forwards;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

// scss-lint:enable NestingDepth

// scss-lint:enable ImportantRule

// Landingspage 2

.landingspage-2 {
    .double-slider-item {
        &__content {
            border-radius: 0;
        }
    }
}
