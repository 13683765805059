.social-follow {
    $self: &;

    &--list {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        margin: 0 0 ($default-margin / 2);

        li {
            display: inline-block;
            margin: 0 5px ($default-margin / 2);

            a {
                position: relative;
                display: block;
                width: 40px;
                height: 40px;
                overflow: hidden;
                text-indent: -99999px;
                border-radius: 50%;
                background-color: transparent;

                &::after {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    display: block;
                    width: 40px;
                    height: 40px;
                    content: "";
                    margin: -20px 0 0 -20px;
                }
            }

            &.facebook {
                a {
                    &::after {
                        @include icon(facebook2, $color-white, 40px, 40px);
                    }

                    &:hover,
                    &:focus {
                        background-color: $color-facebook;
                    }
                }
            }

            &.twitter {
                a {
                    &::after {
                        @include icon(twitter2, $color-white, 40px, 40px);
                    }

                    &:hover,
                    &:focus {
                        background-color: $color-twitter;
                    }
                }
            }

            &.weibo {
                a {
                    &::after {
                        @include icon(weibo, $color-white, 40px, 40px);
                    }

                    &:hover,
                    &:focus {
                        background-color: $color-twitter;
                    }
                }
            }

            &.wechat {
                a {
                    &::after {
                        @include icon(wechat, $color-white, 40px, 40px);
                    }

                    &:hover,
                    &:focus {
                        background-color: $color-twitter;
                    }
                }
            }

            &.tiktok {
                a {
                    &::after {
                        @include icon(tiktok, $color-white, 40px, 40px);
                    }

                    &:hover,
                    &:focus {
                        background-color: $color-twitter;
                    }
                }
            }

            &.youtube {
                a {
                    &::after {
                        @include icon(youtube2, $color-white, 40px, 40px);
                    }

                    &:hover,
                    &:focus {
                        background-color: $color-youtube;
                    }
                }
            }

            &.linkedin {
                a {
                    &::after {
                        @include icon(linkedin2, $color-white, 40px, 40px);
                    }

                    &:hover,
                    &:focus {
                        background-color: $color-linkedin;
                    }
                }
            }

            &.instagram {
                a {
                    &::after {
                        @include icon(instagram2, $color-white, 40px, 40px);
                    }

                    &:hover,
                    &:focus {
                        background-color: $color-instagram;
                    }
                }
            }

            &.pinterest {
                a {
                    &::after {
                        @include icon(pinterest2, $color-white, 40px, 40px);
                    }

                    &:hover,
                    &:focus {
                        background-color: $color-pinterest;
                    }
                }
            }
        }
    }

    &--small {
        &#{ $self }--list {
            li {
                a {
                    width: 30px;
                    height: 30px;

                    &::after {
                        width: 30px;
                        height: 30px;
                    }
                }

                &.facebook {
                    a {
                        &::after {
                            @include icon(facebook2, $color-white, 30px, 30px);
                        }
                    }
                }

                &.twitter {
                    a {
                        &::after {
                            @include icon(twitter2, $color-white, 30px, 30px);
                        }
                    }
                }

                &.youtube {
                    a {
                        &::after {
                            @include icon(youtube2, $color-white, 30px, 30px);
                        }
                    }
                }

                &.linkedin {
                    a {
                        &::after {
                            @include icon(linkedin2, $color-white, 30px, 30px);
                        }
                    }
                }

                &.instagram {
                    a {
                        &::after {
                            @include icon(instagram2, $color-white, 30px, 30px);
                        }
                    }
                }

                &.pinterest {
                    a {
                        &::after {
                            @include icon(pinterest2, $color-white, 30px, 30px);
                        }
                    }
                }
            }
        }
    }
}

.social-share {
    $self: &;
    position: relative;

    &--wrapper {
        position: absolute;
        right: 0;
        bottom: 0;
        width: 300px;
        max-height: 0;
        padding: 0 10px;
        background-color: $color-white;
        overflow: hidden;
        z-index: 10000;
        transition: all .5s;

        @include media-breakpoint-down(sm) {
            left: 0;
            right: 0;
            width: auto;
        }
    }

    &.active {
        #{ $self }--wrapper {
            max-height: 290px;

            @include media-breakpoint-down(sm) {
                max-height: 480px;
            }
        }

        .list {
            &--social-share {
                li {
                    opacity: 1;
                }

                li:nth-child(1) {
                    transition-delay: .15s;
                }

                li:nth-child(2) {
                    transition-delay: .2s;
                }

                li:nth-child(3) {
                    transition-delay: .25s;
                }

                li:nth-child(4) {
                    transition-delay: .3s;
                }

                li:nth-child(5) {
                    transition-delay: .35s;
                }

                li:nth-child(6) {
                    transition-delay: .4s;
                }

                li:nth-child(7) {
                    transition-delay: .45s;
                }
            }
        }
    }

    h2 {
        position: relative;
        padding: 10px 10px 10px 50px;
        margin: 0 -10px 10px;
        color: $color-white;
        font-size: 14px;
        line-height: 30px;
        text-transform: uppercase;
        background-color: $color-black;

        @include media-breakpoint-down(sm) {
            display: block;
        }

        &::before {
            @include icon(share, $color-white, 20px, 20px);
            position: absolute;
            top: 10px;
            left: 10px;
            display: block;
            width: 30px;
            height: 30px;
            content: "";
        }
    }

    .list {
        &--social-share {
            li {
                opacity: 0;
                transition: all .25s;
            }
        }
    }

    input {
        width: 100%;
        height: auto;
        font-family: $text-font;
        font-size: $text-size;
        line-height: $line-height;
        padding: 10px;
        margin: 0 0 10px;
        background: $color-gray;
        border: 0;

        &:focus {
            box-shadow: none;
            outline: none;
        }
    }

    .btn {
        &--social-share {
            padding-left: 30px;
            padding-right: 0;
            background-color: transparent;

            &::after {
                left: 0;
            }

            &:hover,
            &:focus {
                background-color: transparent;
            }
        }

        &--close {
            @include icon(close, $color-white, 20px, 20px);
            position: absolute;
            top: 10px;
            right: 10px;
            display: block;
            width: 30px;
            height: 30px;
            font-size: 0;
            padding: 0;
            border: 0;
            background-color: transparent;

            &:hover,
            &:focus {
                background-color: transparent;
            }
        }
    }
}

// Landingspage 2

.landingspage-2 {
    .social-follow {
        &--list {
            li {
                a {
                    @include icon(polygon, $color-white, 60px, 69px);
                    width: 60px;
                    height: 69px;
                    border-radius: 0;
                }

                &.facebook {
                    a {
                        &::after {
                            @include icon(facebook, $color-white, 40px, 40px);
                        }

                        &:hover,
                        &:focus {
                            background-color: transparent;

                            &::after {
                                @include icon(facebook, $color-facebook, 40px, 40px);
                            }
                        }
                    }
                }

                &.twitter {
                    a {
                        &::after {
                            @include icon(twitter, $color-white, 40px, 40px);
                        }

                        &:hover,
                        &:focus {
                            background-color: transparent;

                            &::after {
                                @include icon(twitter, $color-twitter, 40px, 40px);
                            }
                        }
                    }
                }

                &.youtube {
                    a {
                        &::after {
                            @include icon(youtube, $color-white, 40px, 40px);
                        }

                        &:hover,
                        &:focus {
                            background-color: transparent;

                            &::after {
                                @include icon(youtube, $color-youtube, 40px, 40px);
                            }
                        }
                    }
                }

                &.linkedin {
                    a {
                        &::after {
                            @include icon(linkedin, $color-white, 40px, 40px);
                        }

                        &:hover,
                        &:focus {
                            background-color: transparent;

                            &::after {
                                @include icon(linkedin, $color-linkedin, 40px, 40px);
                            }
                        }
                    }
                }

                &.instagram {
                    a {
                        &::after {
                            @include icon(instagram, $color-white, 40px, 40px);
                        }

                        &:hover,
                        &:focus {
                            background-color: transparent;

                            &::after {
                                @include icon(instagram, $color-instagram, 40px, 40px);
                            }
                        }
                    }
                }

                &.pinterest {
                    a {
                        &::after {
                            @include icon(pinterest, $color-white, 40px, 40px);
                        }

                        &:hover,
                        &:focus {
                            background-color: transparent;

                            &::after {
                                @include icon(pinterest, $color-pinterest, 40px, 40px);
                            }
                        }
                    }
                }
            }
        }
    }
}
