// USP block

.block {
    &--usps {
        margin: 0;
    }
}

.usps {
    display: flex;
    flex-direction: column;

    &__title {
        text-align: center;
    }
}

.usps-overview {
    @include make-row;
    justify-content: center;
    margin: 0 -15px ($default-block-margin - 60px);

    &__item {
        @include make-col(4);
        padding: 0 15px;

        @include media-breakpoint-down(md) {
            @include make-col(6);
        }

        @include media-breakpoint-down(sm) {
            @include make-col(12);
        }
    }
}

.usp {
    $self: &;
    display: flex;
    align-items: center;
    min-height: 60px;
    color: $color-red;
    margin: 0 0 60px;

    &__icon {
        position: relative;
        width: 60px;
        height: 60px;
        border: 2px solid $color-red;
        border-radius: 50%;

        img {
            position: absolute;
            top: 50%;
            left: 50%;
            width: auto;
            max-width: 30px;
            height: auto;
            max-height: 30px;
            transform: translate(-50%, -50%);
        }

        + #{ $self }__content {
            padding-left: 20px;
        }
    }

    &__content {
        flex: 1;
    }
}

// Landingspage 2

.landingspage-2 {
    .usp {
        color: $color-blue;

        &__icon {
            border-color: $color-blue;
            border-width: 1px;
        }
    }
}
