// History Slider block

.block {
    &--history-slider {
        margin: 0;
    }
}

.history-block {
    $self: &;

    @include make-row;
    flex-direction: column;
    align-items: center;
    margin: 0 -15px;

    &__title {
        @include make-col(6);
        margin: 0 0 10px;
        padding: 0 15px;
        text-align: center;

        @include media-breakpoint-down(md) {
            @include make-col(12);
        }

        .is-h2 {
            text-transform: uppercase;
        }

        + #{ $self }__content {
            margin-top: -40px;
        }
    }

    &__content {
        @include make-col(6);
        padding: 0 15px;
        margin: 0 0 10px;
        text-align: center;

        @include media-breakpoint-down(md) {
            @include make-col(12);
        }
    }
}

// scss-lint:disable ImportantRule
.history-slider-item {
    display: flex;
    flex-direction: column;
    margin: 0 0 ($default-block-margin - $default-margin);

    @include media-breakpoint-down(md) {
        padding: 260px 0 0;
        margin-bottom: 0;
    }

    &__image {
        overflow: hidden;
        margin: 10px 0 $default-margin;
        background-color: $color-white;
        box-shadow: 0 0 20px rgba($color-extra-black, .2);
        border-radius: 5px;

        @include media-breakpoint-down(md) {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            height: 328px;
            margin: 0;
            box-shadow: none;
            border-radius: 0;

            img {
                position: absolute;
                top: 50%;
                left: 50%;
                width: auto !important;
                min-width: 100% !important;
                max-width: none !important;
                height: auto !important;
                min-height: 100% !important;
                transform: translate(-50%, -50%);
            }
        }
    }

    &__content {
        @extend .wrapper;
        width: 100%;
        padding: 0 20px;
        margin: 0 auto;

        @include media-breakpoint-down(md) {
            padding: $default-margin 40px 0;
            margin-bottom: $default-block-margin;
            background-color: $color-white;
            box-shadow: 0 0 20px rgba($color-extra-black, .2);
            border-radius: 5px;
        }

        .is-h3 {
            text-transform: uppercase;
        }

        .is-h6 {
            font-size: 24px;
            line-height: 32px;

            @include media-breakpoint-down(md) {
                font-size: 14px;
                line-height: 24px;
            }

            + .is-h3 {
                margin-top: -$default-margin;
            }
        }

        .richtext {
            padding-left: 120px;

            @include media-breakpoint-down(md) {
                padding-left: 0;
            }

            + p {
                padding-left: 120px;

                @include media-breakpoint-down(md) {
                    padding-left: 0;
                }
            }
        }
    }
}

// scss-lint:enable ImportantRule

// scss-lint:disable ImportantRule
.history-slider {
    $self: &;
    display: flex !important;
    justify-content: center;
    margin: 0;

    &__item {
        flex: 1;
        max-width: 590px;
        padding: 0 15px;

        @include media-breakpoint-down(md) {
            max-width: 100%;

            &:nth-child(1) {
                ~ #{ $self }__item {
                    display: none;
                }
            }
        }

        &:nth-child(2) {
            ~ #{ $self }__item {
                display: none;
            }
        }
    }

    &.owl-carousel {
        .owl-item {
            .history-slider-item {
                &__content {
                    opacity: 0;
                    transform: translateY(-150px);
                    transition: all .3s;

                    @include media-breakpoint-down(md) {
                        opacity: 1;
                        transform: translateY(0);
                    }
                }
            }

            &.active.center {
                .history-slider-item {
                    &__content {
                        opacity: 1;
                        transform: translateY(0);
                    }
                }
            }
        }

        .owl-nav {
            @include make-container-max-widths;
            top: 50%;
            left: 0;
            right: 0;
            margin: 0 auto;
            padding: 0 15px;
            justify-content: flex-end;

            @include media-breakpoint-down(md) {
                top: 290px;
            }

            @include media-breakpoint-up(lg) {
                top: 300px;
            }

            @media (min-width: 1200px) {
                top: 320px;
            }

            @media (min-width: 1300px) {
                top: 330px;
            }

            @media (min-width: 1400px) {
                top: 360px;
            }

            @media (min-width: 1500px) {
                top: 390px;
            }

            @media (min-width: 1600px) {
                top: 420px;
            }

            &.disabled {
                display: flex;
            }

            button {
                &.owl-prev,
                &.owl-next {
                    @include icon(arrow-big, $color-black, 22px, 34px);
                    width: 40px;
                    height: 40px;
                    opacity: .28;

                    @include media-breakpoint-down(md) {
                        @include icon(arrow-big, $color-black, 12px, 18px);
                        width: 30px;
                        height: 30px;
                    }

                    &:hover,
                    &:focus {
                        opacity: 1;
                    }
                }
            }
        }

        .owl-counter {
            display: flex;
            height: 40px;
            justify-content: center;
            transform: translateY(-50%);

            @include media-breakpoint-down(md) {
                height: 30px;
            }

            span {
                color: $color-black;
                font-size: 18px;
                line-height: 28px;
                font-weight: 900;
                margin: 0 5px;
                padding: 6px 0;

                @include media-breakpoint-down(md) {
                    margin: 0 3px;
                    padding: 1px 0;
                }
            }
        }
    }
}

// scss-lint:enable ImportantRule
