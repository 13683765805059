// Lightcase override

// scss-lint:disable ImportantRule

// scss-lint:disable IdSelector

// scss-lint:disable SelectorFormat
a[class*="lightcase-icon-"] {
    text-decoration: none;

    &:hover {
        text-decoration: none;
    }
}

.lightcase-popup-content {
    display: none;
}

#lightcase-case {
    font-family: $text-font;
    color: $text-color;
    font-weight: 400;
    font-size: $text-size;
    line-height: $line-height;
    text-shadow: none !important;

    @include media-breakpoint-down(sm) {
        color: $color-white !important;
    }

    #lightcase-content {
        .lightcase-contentInner {
            .lightcase-inlineWrap {
                padding: 10px 15px 0 !important;

                @include media-breakpoint-down(sm) {
                    background-color: $color-white !important;
                }

                h1,
                h2,
                h3,
                h4,
                h5,
                h6,
                p {
                    color: $text-color !important;

                    @include media-breakpoint-down(sm) {
                        color: $text-color !important;
                    }
                }

                .lightcase-popup-content {
                    display: flex;
                    flex-direction: column;
                    min-width: 462px;

                    @include media-breakpoint-down(sm) {
                        min-width: 0;
                    }

                    > * {
                        margin: 0 0 15px;
                    }
                }
            }
        }
    }
}

// scss-lint:enable SelectorFormat

// scss-lint:enable IdSelector

// scss-lint:enable ImportantRule
