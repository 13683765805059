.block {
    &--table-downloads {
        margin: 0;
    }
}

.table-downloads {
    width: 83.3333%;
    margin: 0 auto;

    @include media-breakpoint-down(md) {
        width: auto;
        margin: 0;
    }
}

.table {
    &--downloads {
        border-collapse: collapse;
        width: 100%;
        border-radius: 5px;
        overflow: hidden;
        margin: 0 0 90px;
        box-shadow: 0 0 20px rgba($color-extra-black, .2);

        @include media-breakpoint-down(sm) {
            display: block;
            background: transparent;
            margin: 0 0 30px;
            box-shadow: 0 0 0 rgba($color-extra-black, .2);
        }

        > *,
        > * > tr,
        > * > tr > * {
            @include media-breakpoint-down(sm) {
                display: block;
            }
        }

        td,
        th {
            border: transparent 1px solid;
            padding: 25px 20px;
            text-align: left;
        }

        th {
            background: $color-gray-dark;
            color: $color-white;
            font-size: 16px;
            line-height: 26px;
            text-transform: uppercase;
            font-weight: 900;
            padding: 16px 20px;

            &:nth-of-type(1) {
                width: auto;
            }

            &:nth-of-type(2) {
                width: 15%;
            }

            &:nth-of-type(3s) {
                width: 28%;
            }

            &:nth-of-type(4) {
                width: 80px;
            }

            &:nth-of-type(5) {
                width: 110px;
            }

            &:nth-of-type(6) {
                width: 18%;
            }
        }

        tbody {
            tr {
                @include media-breakpoint-down(sm) {
                    border-radius: 5px;
                    box-shadow: 0 0 20px rgba($color-extra-black, .2);
                }

                &:nth-of-type(even) {
                    background: $color-gray-light;
                }

                td {
                    font-size: 16px;
                    line-height: 26px;
                    font-weight: 500;

                    @include media-breakpoint-down(sm) {
                        text-align: left;
                        padding: 0;
                        display: flex;
                        align-items: stretch;
                        border: 0;
                        border-bottom: 1px solid $color-gray-light;
                    }

                    span {
                        @include media-breakpoint-down(sm) {
                            padding: 5px 10px;
                            flex: 1;
                        }
                    }

                    &::before {
                        content: attr(data-title);
                        display: none;
                        padding: 5px 10px;
                        flex: 1;
                        font-weight: 800;
                        text-transform: uppercase;
                        border-right: 1px solid $color-black;

                        @include media-breakpoint-down(sm) {
                            display: inline-block;
                        }
                    }

                    &.file-title {
                        @include media-breakpoint-down(sm) {
                            font-weight: 700;
                            background: $color-gray-dark;
                            border-bottom: transparent 2px solid;
                            color: $color-white;
                            text-align: center;
                        }

                        &::before {
                            @include media-breakpoint-down(sm) {
                                display: none;
                            }
                        }
                    }

                    &.file-link {
                        @include media-breakpoint-down(sm) {
                            text-align: center;
                            border-top: 1px solid $color-black;
                            padding-top: 15px;
                        }

                        &::before {
                            @include media-breakpoint-down(sm) {
                                display: none;
                            }
                        }

                        .btn {
                            @include media-breakpoint-down(sm) {
                                color: $color-white;
                                padding: 5px 15px;
                                text-decoration: none;
                                background: $color-red;
                                margin-bottom: 15px;
                            }
                        }
                    }

                    &.file-empty {
                        @include media-breakpoint-down(sm) {
                            text-align: center;
                            border-top: 1px solid $color-black;
                            padding-top: 15px;
                        }

                        &::before {
                            @include media-breakpoint-down(sm) {
                                display: none;
                            }
                        }

                        .btn {
                            font-weight: 400;
                            font-style: italic;
                            opacity: .5;

                            @include media-breakpoint-down(sm) {
                                color: $color-white;
                                padding: 5px 15px;
                                text-decoration: none;
                                background: $color-red;
                                margin-bottom: 15px;
                            }
                        }
                    }
                }
            }
        }

        thead {
            @include media-breakpoint-down(sm) {
                display: none;
            }
        }

        > * > tr {
            @include media-breakpoint-down(sm) {
                margin-bottom: 15px;
                border: 2px solid $color-black;
            }
        }
    }
}
