// UBR block

.block {
    &--ubrs {
        margin: 0;
    }
}

.ubrs {
    display: flex;
    flex-direction: column;

    &__title {
        text-align: center;
    }
}

.ubrs-overview {
    @include make-row;
    justify-content: center;
    margin: 0 -15px;

    &__item {
        @include make-col(4);
        padding: 0 15px;

        @include media-breakpoint-down(md) {
            @include make-col(12);
        }
    }
}

.ubr {
    display: flex;
    flex-direction: column;
    margin: 0 0 ($default-block-margin - $default-margin);

    &__image {
        margin: 0 0 $default-margin;

        img {
            display: block;
            width: auto;
            max-width: 90px;
            height: auto;
            max-height: 90px;
            margin: 0 auto;
        }
    }

    &__content {
        text-align: center;

        .is-h4 {
            + .richtext {
                margin-top: -$default-margin;
            }
        }

        .richtext {
            + .read-more {
                margin-top: -$default-margin;
            }
        }
    }
}
