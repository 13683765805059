// Content Image block

.block {
    &--content-image-block {
        background-color: $color-red;
        margin: 0 0 $default-block-margin;

        &:last-child {
            margin-bottom: 0;
        }

        .content-image-block {
            color: $color-white;

            .is-h2,
            .is-h3,
            .is-h4,
            .is-h5 {
                color: $color-white;
            }
        }

        .wrapper {
            padding: 0;

            @include media-breakpoint-up(xl) {
                max-width: 1440px;
            }
        }
    }
}

.content-image-block {
    $self: &;

    @include make-row;
    margin: 0;

    &__image {
        @include make-col(6);
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 0 auto 0 0;

        @include media-breakpoint-down(sm) {
            @include make-col(12);
        }
    }

    &__content {
        @include make-col(6);
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: $default-margin 60px 0;
        margin: 0 0 0 auto;

        @include media-breakpoint-down(sm) {
            @include make-col(12);
            padding: $default-margin 15px 0;
        }
    }

    &--reversed {
        #{ $self }__image {
            margin-right: 0;
            margin-left: auto;
            order: 2;

            @include media-breakpoint-down(sm) {
                order: 1;
            }
        }

        #{ $self }__content {
            margin-right: auto;
            margin-left: 0;
            order: 1;

            @include media-breakpoint-down(sm) {
                order: 2;
            }
        }
    }
}

// Landingspage 2

.landingspage-2 {
    .block {
        &--content-image-block {
            background-color: $color-blue;
        }
    }
}
