// Bootstrap settings
$container-max-widths: (
    "sm": 540px,
    "md": 720px,
    "lg": 960px,
    "xl": 1170px
) !default;

// Lightcase settings
$lightcase-custom: (
    "font-path": "/assets/fonts/",
    "breakpoint": 768px
) !default;

// Overall
$default-margin: 40px;
$default-block-margin: 100px;
$heading-font: "Maven Pro", sans-serif;
$text-font: "Maven Pro", sans-serif;
$text-size: 16px;
$line-height: 28px;
$text-color: #121212;
$color-white: #fff;
$color-black: #121212;
$color-extra-black: #000;
$color-gray-dark: #282a2a;
$color-red: #e60013;
$color-red-dark: #cf000d;
$color-gray: #afafaf;
$color-gray-light: #f5f5f5;
$color-email: #0a88ff;
$color-newsletter: #0a88ff;
$color-facebook: #3b5998;
$color-twitter: #1da1f2;
$color-youtube: #f00;
$color-linkedin: #0077b5;
$color-instagram: #833ab4;
$color-pinterest: #bd081c;
$color-whatsapp: #25d366;
$color-black-rgba: rgb(0 0 0 / 1);
$color-black-rgba-transparant: rgb(0 0 0 / .3);
$color-black-rgba-dark-transparant: rgb(0 0 0 / .9);

// Landingspage 2
$heading-font2: "Lato", sans-serif;
$text-font2: "Lato", sans-serif;
$color-blue: #000cff;
$color-blue-dark: #000450;
$color-green: #31d0b0;
