.search-result {
    &--block {
        color: $text-color;
        text-decoration: none;
        margin: 0 0 10px;

        &:hover,
        &:focus {
            color: $text-color;
            text-decoration: none;
        }

        &__content {
            display: flex;
            flex-direction: column;
        }
    }
}

.search-results {
    &--list {
        display: flex;
        flex-direction: column;
        margin: 0 0 20px;

        &__item,
        &__empty-item {
            display: flex;
            flex-direction: column;
        }

        h2 {
            margin-bottom: 0;
            font-size: 42px;
            line-height: 44px;
        }

        .url {
            display: block;
            color: $red;
            margin-bottom: 20px;
            font-size: 14px;
            line-height: 20px;
            font-style: italic;
        }
    }
}
