// TwentyTwenty override

// scss-lint:disable ImportantRule
.twentytwenty-wrapper {
    .twentytwenty-container {
        height: auto !important;

        img {
            z-index: 1;

            &:first-child {
                position: relative;
                z-index: 0;
            }
        }
    }

    .twentytwenty-handle {
        border-radius: 50%;
        background: $color-white;
        border: 0;

        .twentytwenty-left-arrow,
        .twentytwenty-right-arrow {
            @include icon(arrow, $color-black, 14px, 14px);
            position: absolute;
            top: 50%;
            display: block;
            width: 14px;
            height: 14px;
            padding: 0;
            border: 0;
        }

        .twentytwenty-left-arrow {
            left: 50%;
            margin: -7px 0 0 -14px;
        }

        .twentytwenty-right-arrow {
            right: 50%;
            margin: -7px -14px 0 0;
            transform: rotate(-180deg);
        }
    }
}

// scss-lint:enable ImportantRule
