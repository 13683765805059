.block {
    &--slider-gallery {
        margin: 0 0 $default-block-margin;
        background-color: $color-black;

        .slider {
            margin: 0;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.slider-gallery {
    $self: &;
    display: block;
    position: relative;
    overflow: hidden;
    margin: 0 auto;
    max-width: 1615px;
    background-color: $color-black;

    &.owl-carousel {
        .owl-nav {
            top: auto;
            bottom: 20px;
            justify-content: flex-end;
            margin: 0 auto;

            @include media-breakpoint-down(md) {
                inset: 65vw 10px auto;
                justify-content: space-between;
            }

            @include media-breakpoint-down(xs) {
                top: 72vw;
            }

            button {
                &.owl-prev,
                &.owl-next {
                    @include icon(arrow, $color-white, 13px, 30px);
                    width: 30px;
                    height: 30px;
                    margin: 0 40px 0 0;

                    @include media-breakpoint-down(md) {
                        margin: 0;
                    }

                    &:hover,
                    &:focus {
                        @include icon(arrow, $color-white, 13px, 30px);
                    }
                }
            }
        }
    }

    &__item {
        flex: 0 0 100%;

        + #{ $self }__item {
            display: none;
        }
    }
}

.slider-gallery-item {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    width: 100%;

    @include media-breakpoint-down(md) {
        flex-direction: column;
        min-height: 0;
    }

    &__image {
        flex: 0 0 48%;
        max-width: 48%;
        margin: 0 auto 0 0;

        @include media-breakpoint-down(md) {
            flex: 0 0 100%;
            max-width: 100%;
            margin: 0;
        }
    }

    &__content {
        flex: 0 0 50%;
        max-width: 50%;
        padding: 80px 15px 0;
        margin: 0 0 0 auto;

        @include media-breakpoint-down(md) {
            flex: 0 0 100%;
            max-width: 100%;
            padding: 40px 50px 0;
        }

        .richtext {
            * {
                color: $color-white;
                margin: 0 0 20px;
            }
        }
    }
}

.slider-gallery-navigation {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0;
    padding: 25px 0 0;
    background-color: $color-black;

    @include media-breakpoint-down(sm) {
        flex-direction: column;
        display: none;
    }

    li {
        padding: 0 15px;
        margin: 0 0 25px;
        max-width: 240px;
        opacity: .3;
        transition: all .3s;

        @include media-breakpoint-down(sm) {
            max-width: none;
        }

        &:hover,
        &:focus,
        &.active {
            opacity: 1;
        }

        a {
            display: flex;
            align-items: center;
            color: $color-white;
            font-size: 14px;
            line-height: 22px;
            text-decoration: none;

            &:hover,
            &:focus {
                text-decoration: none;
            }

            i {
                display: flex;
                justify-content: center;
                flex-shrink: 0;
                width: 46px;
                height: 46px;
                margin: 0 20px 0 0;
                border: 1px solid $color-white;
                border-radius: 50%;

                img {
                    max-width: 30px;
                }
            }
        }
    }
}
