button {
    cursor: pointer;
    border: 0;
    outline: none;
    background: transparent;

    &:hover,
    &:focus {
        outline: none;
    }
}

.btn {
    $self: &;
    position: relative;
    display: inline-block;
    color: $color-white;
    font-size: 16px;
    line-height: 22px;
    padding: 7px 18px;
    font-weight: 900;
    border: 2px solid $color-red;
    background-color: $color-red;
    border-radius: 20px;
    text-transform: uppercase;
    font-family: $heading-font;
    transition: all .3s;
    text-align: center;

    + .btn {
        margin-left: 15px;

        @include media-breakpoint-down(sm) {
            margin-left: 0;
            margin-top: 20px;
        }
    }

    &:hover,
    &:focus {
        color: $color-white;
        text-decoration: none;
        border-color: darken($color-red, 10%);
        background-color: darken($color-red, 10%);
    }

    &--big {
        font-size: 22px;
        line-height: 30px;
        padding: 12px 20px;
    }

    &--white {
        color: $color-black;
        border-color: $color-white;
        background-color: $color-white;

        &:hover,
        &:focus {
            color: $color-black;
            border-color: darken($color-white, 10%);
            background-color: darken($color-white, 10%);
        }
    }

    &--black {
        color: $color-white;
        border-color: $color-black;
        background-color: $color-black;

        &:hover,
        &:focus {
            color: $color-white;
            border-color: $color-red;
            background-color: $color-red;
        }
    }

    &--blue {
        color: $color-white;
        border-color: $color-blue;
        background-color: $color-blue;

        &:hover,
        &:focus {
            color: $color-white;
            border-color: darken($color-blue, 10%);
            background-color: darken($color-blue, 10%);
        }
    }

    &--green {
        color: $color-white;
        border-color: $color-green;
        background-color: $color-green;

        &:hover,
        &:focus {
            color: $color-white;
            border-color: darken($color-green, 10%);
            background-color: darken($color-green, 10%);
        }
    }

    &--outline {
        color: $color-red;
        border-color: $color-red;
        background-color: transparent;

        &:hover,
        &:focus {
            color: $color-white;
            border-color: darken($color-red, 10%);
            background-color: darken($color-red, 10%);
        }

        &#{ $self }--white {
            color: $color-white;
            border-color: $color-white;

            &:hover,
            &:focus {
                color: $color-black;
                border-color: darken($color-white, 10%);
                background-color: darken($color-white, 10%);
            }
        }

        &#{ $self }--black {
            color: $color-black;
            border-color: $color-black;

            &:hover,
            &:focus {
                color: $color-white;
                border-color: $color-red;
                background-color: $color-red;
            }
        }

        &#{ $self }--blue {
            color: $color-blue;
            border-color: $color-blue;

            &:hover,
            &:focus {
                color: $color-white;
                border-color: darken($color-blue, 10%);
                background-color: darken($color-blue, 10%);
            }
        }

        &#{ $self }--green {
            color: $color-green;
            border-color: $color-green;

            &:hover,
            &:focus {
                color: $color-white;
                border-color: darken($color-green, 10%);
                background-color: darken($color-green, 10%);
            }
        }
    }

    &--link {
        color: $text-color;
        font-size: $text-size;
        line-height: $line-height;
        font-weight: 700;
        text-transform: none;
        padding: 0;
        border: 0;
        background-color: transparent;

        &:hover,
        &:focus {
            color: $color-red;
            background-color: transparent;
        }
    }

    &--description {
        color: $text-color;
        font-size: 13px;
        line-height: 14px;
        font-weight: 700;
        text-transform: uppercase;
        text-align: left;
        padding: 4px 0 4px 45px;
        border-color: transparent;
        background-color: transparent;

        @include media-breakpoint-down(md) {
            width: 40px;
            height: 40px;
            font-size: 0;
            padding: 0;
        }

        &::before {
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            width: 40px;
            height: 40px;
            content: "";
            transition: all .3s;
        }

        span {
            display: block;
            font-size: 9px;
            font-weight: 500;
            opacity: .66;

            @include media-breakpoint-down(md) {
                font-size: 0;
            }
        }

        &:hover,
        &:focus {
            color: $color-red;
            border-color: transparent;
            background-color: transparent;

            span {
                opacity: 1;
            }
        }

        &.btn {
            &--phone {
                &::before {
                    @include icon(phone, $text-color, 40px, 40px);
                }

                &:hover,
                &:focus {
                    &::before {
                        @include icon(phone, $color-red, 40px, 40px);
                    }
                }
            }

            &--account {
                &::before {
                    @include icon(account, $text-color, 40px, 40px);
                }

                &:hover,
                &:focus {
                    &::before {
                        @include icon(account, $color-red, 40px, 40px);
                    }
                }
            }
        }
    }

    &--social-share {
        color: $color-white;
        font-size: 14px;
        line-height: 22px;
        padding: 7px 15px 7px 45px;
        border: 0;
        background-color: transparent;

        &:hover,
        &:focus {
            border-color: transparent;
            background-color: transparent;
        }

        &::after {
            @include icon(share, $color-white, 20px, 20px);
            position: absolute;
            top: 50%;
            left: 10px;
            display: block;
            width: 20px;
            height: 20px;
            margin: -10px 0 0;
            content: "";
        }
    }

    &--to-top {
        @include icon(arrow, $color-white, 20px, 20px);
        position: fixed;
        right: 20px;
        bottom: 60px;
        display: none;
        width: 45px;
        height: 45px;
        font-size: 0;
        padding: 0;
        z-index: 2000;
        transform: rotate(90deg);
        border-radius: 50%;

        @include media-breakpoint-down(sm) {
            display: none;
            bottom: 20px;
        }

        &:hover,
        &:focus {
            background-color: darken($color-red, 10%);
        }
    }

    &--whatsapp {
        background-color: $color-whatsapp;
        padding-left: 40px;

        &:hover,
        &:focus {
            background-color: darken($color-whatsapp, 10%);
        }

        &::after {
            @include icon(whatsapp, $color-white, 24px, 24px);
            content: "";
            height: 24px;
            left: 10px;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            width: 24px;
        }
    }

    &--video-play,
    &--video-pause {
        position: relative;
        color: $color-white;
        font-weight: 900;
        text-transform: uppercase;
        font-size: 14px;
        line-height: 30px;
        border: 0;
        padding: 15px 15px 15px 75px;
        transition: transform .3s ease-in-out;
        background-color: transparent;

        &::before {
            @include icon(playbtn, $color-white, 20px, 20px);
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            width: 60px;
            height: 60px;
            content: "";
            border-radius: 50%;
            border: 2px solid $color-white;
        }

        &:hover,
        &:focus {
            background-color: transparent;
        }
    }

    &--video-pause {
        &::before {
            @include icon(close, $color-white, 20px, 20px);
        }
    }
}

// Landingspage 2

.landingspage-2 {
    .btn {
        $self: ".btn";
        color: $color-white;
        border-color: $color-blue;
        background-color: $color-blue;
        border-radius: 0;
        border-width: 1px;
        padding: 8px 19px;

        &:hover,
        &:focus {
            color: $color-white;
            border-color: darken($color-blue, 10%);
            background-color: darken($color-blue, 10%);
        }

        &--white {
            color: $color-black;
            border-color: $color-white;
            background-color: $color-white;

            &:hover,
            &:focus {
                color: $color-black;
                border-color: darken($color-white, 10%);
                background-color: darken($color-white, 10%);
            }
        }

        &--black {
            color: $color-white;
            border-color: $color-black;
            background-color: $color-black;

            &:hover,
            &:focus {
                color: $color-white;
                border-color: $color-blue;
                background-color: $color-blue;
            }
        }

        &--blue {
            color: $color-white;
            border-color: $color-blue;
            background-color: $color-blue;

            &:hover,
            &:focus {
                color: $color-white;
                border-color: darken($color-blue, 10%);
                background-color: darken($color-blue, 10%);
            }
        }

        &--green {
            color: $color-white;
            border-color: $color-green;
            background-color: $color-green;

            &:hover,
            &:focus {
                color: $color-white;
                border-color: darken($color-green, 10%);
                background-color: darken($color-green, 10%);
            }
        }

        &--outline {
            color: $color-blue;
            background-color: transparent;

            &:hover,
            &:focus {
                color: $color-white;
                border-color: $color-blue;
                background-color: $color-blue;
            }

            &#{ $self }--white {
                color: $color-white;
                border-color: $color-white;

                &:hover,
                &:focus {
                    color: $color-black;
                    border-color: darken($color-white, 10%);
                    background-color: darken($color-white, 10%);
                }
            }

            &#{ $self }--black {
                color: $color-black;
                border-color: $color-black;

                &:hover,
                &:focus {
                    color: $color-white;
                    border-color: $color-blue;
                    background-color: $color-blue;
                }
            }

            &#{ $self }--blue {
                color: $color-blue;
                border-color: $color-blue;

                &:hover,
                &:focus {
                    color: $color-white;
                    border-color: darken($color-blue, 10%);
                    background-color: darken($color-blue, 10%);
                }
            }

            &#{ $self }--green {
                color: $color-green;
                border-color: $color-green;

                &:hover,
                &:focus {
                    color: $color-white;
                    border-color: darken($color-green, 10%);
                    background-color: darken($color-green, 10%);
                }
            }
        }

        &--link {
            color: $text-color;
            padding: 0;
            background-color: transparent;

            &:hover,
            &:focus {
                color: $color-blue;
                background-color: transparent;
            }
        }

        &--description {
            color: $text-color;
            border-color: transparent;
            background-color: transparent;
            padding: 4px 0 4px 45px;

            &:hover,
            &:focus {
                color: $color-blue;
                border-color: transparent;
                background-color: transparent;
            }

            &.btn {
                &--phone {
                    &:hover,
                    &:focus {
                        &::before {
                            @include icon(phone, $color-blue, 40px, 40px);
                        }
                    }
                }

                &--account {
                    &:hover,
                    &:focus {
                        &::before {
                            @include icon(account, $color-blue, 40px, 40px);
                        }
                    }
                }
            }
        }

        &--video-play,
        &--video-pause {
            padding: 15px 15px 15px 75px;
            background-color: transparent;

            &:hover,
            &:focus {
                background-color: transparent;
            }
        }
    }
}
