// Accessories block

.block {
    &--accessories {
        position: relative;
        margin-bottom: 0;
        z-index: 1;

        + .block {
            &--double-slider {
                margin-top: -175px;
            }
        }
    }
}

.accessories {
    display: flex;
    flex-direction: column;
    margin: 0 0 ($default-block-margin - $default-margin);

    &__title {
        text-align: center;
    }
}

.accessory {
    $self: &;
    display: block;
    overflow: hidden;
    background-color: $color-white;
    margin: 0 0 $default-margin;
    border-radius: 5px;
    transition: all .3s;

    &[href] {
        color: $text-color;
        text-decoration: none;

        &:hover,
        &:focus {
            color: $text-color;
            background-color: darken($color-white, 10%);

            #{ $self }__image {
                img {
                    transform: scale(1.1);
                }
            }
        }
    }

    &__image {
        overflow: hidden;

        img {
            transition: all .3s;
        }
    }

    &__content {
        padding: 15px 20px;

        > * {
            margin: 0;
        }
    }
}

// scss-lint:disable ImportantRule
.slider-accessories {
    $self: &;
    display: flex;
    flex-direction: row;
    width: auto;
    margin: 0 -10px $default-margin;

    &__item {
        display: flex;
        flex-direction: column;
        flex: 0 0 25%;
        max-width: 25%;
        padding: 0 10px;

        @include media-breakpoint-down(md) {
            flex: 0 0 50%;
            max-width: 50%;

            &:nth-child(2) {
                ~ #{ $self }__item {
                    display: none;
                }
            }
        }

        @include media-breakpoint-down(sm) {
            flex: 0 0 100%;
            max-width: 100%;

            &:nth-child(1) {
                ~ #{ $self }__item {
                    display: none;
                }
            }
        }

        &:nth-child(4) {
            ~ #{ $self }__item {
                display: none;
            }
        }

        .accessory {
            height: 100%;
            margin: 0;
        }
    }

    &.owl-carousel {
        display: flex !important;

        .owl-stage {
            padding-left: 0 !important;
        }

        .owl-stage-outer {
            padding: 15px 5px;
        }

        .owl-item {
            #{ $self }__item {
                flex: 0 0 100%;
                max-width: 100%;
            }
        }

        .owl-nav {
            right: -40px;
            left: -40px;

            @include media-breakpoint-down(lg) {
                right: 0;
                left: 0;
            }

            button {
                &.owl-prev,
                &.owl-next {
                    @include icon(arrow-big, $color-black, 22px, 34px);
                    width: 40px;
                    height: 40px;
                    opacity: .28;

                    @include media-breakpoint-down(sm) {
                        @include icon(arrow-big, $color-black, 12px, 18px);
                        width: 30px;
                        height: 30px;
                    }

                    &:hover,
                    &:focus {
                        opacity: 1;
                    }

                    &.disabled {
                        visibility: hidden;
                    }
                }
            }
        }
    }
}

// scss-lint:enable ImportantRule

// Landingspage 2

.landingspage-2 {
    .accessory {
        border-radius: 0;
        box-shadow: 0 0 20px 0 transparentize($color-extra-black, .8);
    }
}
