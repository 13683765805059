// Testimonial block

.block {
    &--testimonial {
        margin: 0 0 $default-block-margin;

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.testimonial {
    $self: &;
    position: relative;
    display: flex;
    align-items: center;
    min-height: 660px;
    background-color: $color-black;

    @include media-breakpoint-down(sm) {
        min-height: 0;
        padding-top: 70px;
    }

    &__image {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        max-width: 60%;
        height: 100%;
        overflow: hidden;

        @include media-breakpoint-down(sm) {
            width: 100%;
            max-width: none;
        }

        &::before,
        &::after {
            position: absolute;
            top: -10%;
            display: block;
            width: 600px;
            height: 200%;
            content: "";
            z-index: 1;

            @include media-breakpoint-down(sm) {
                width: 100%;
            }
        }

        &::before {
            right: 0;
            background: linear-gradient(90deg, transparentize($color-black, 1) 0, transparentize($color-black, 0) 80%);

            @include media-breakpoint-down(sm) {
                background: linear-gradient(180deg, transparentize($color-black, 1) 0, transparentize($color-black, 1) 100px, transparentize($color-black, 0) 250px, transparentize($color-black, 0) 100%);
            }
        }

        &::after {
            left: 100%;
            background-color: $color-black;

            @include media-breakpoint-down(sm) {
                display: none;
            }
        }

        img {
            position: relative;
            width: auto;
            max-width: none;
            height: 100%;
            z-index: 0;

            @include media-breakpoint-down(md) {
                left: 50%;
                transform: translateX(-50%);
            }

            @include media-breakpoint-down(sm) {
                width: 100%;
                max-width: 100%;
                height: auto;
                left: 0;
                transform: none;
            }
        }
    }

    &__content {
        @extend .wrapper;
        position: relative;
        display: flex;
        justify-content: flex-end;
        color: $color-white;
        margin: 0 auto;
        padding: 88px 15px 10px;
        z-index: 1;

        @include media-breakpoint-down(md) {
            padding: 58px 15px 10px;
        }

        @include media-breakpoint-down(sm) {
            padding: 30px 15px 10px;
        }

        .richtext {
            width: 50%;

            @include media-breakpoint-down(sm) {
                width: 100%;
            }

            > * {
                color: $color-white;
                margin: 0 0 20px;

                &:last-child {
                    margin: 0;
                }

                &:not(.is-h4) {
                    font-family: Lora, serif;
                    font-size: 24px;
                    line-height: 32px;
                    opacity: .5;
                }
            }

            .is-h4 {
                span {
                    display: block;
                    font-size: $text-size;
                    line-height: $line-height;
                }
            }
        }
    }

    &--reversed {
        #{ $self }__image {
            left: auto;
            right: 0;

            &::before {
                left: 0;
                right: auto;
                transform: rotate(-180deg);

                @include media-breakpoint-down(sm) {
                    transform: none;
                }
            }
        }

        #{ $self }__content {
            justify-content: flex-start;
        }
    }
}

// Landingspage 2

.landingspage-2 {
    .testimonial {
        background-color: $color-green;

        &__image {
            &::before {
                right: 15%;
                transform: rotate(10deg);
                background: linear-gradient(90deg, transparentize($color-green, 1) 0, transparentize($color-green, 0) 80%);

                @include media-breakpoint-down(sm) {
                    background: linear-gradient(180deg, transparentize($color-green, 1) 0, transparentize($color-green, 1) 100px, transparentize($color-green, 0) 250px, transparentize($color-green, 0) 100%);
                }

                @include media-breakpoint-down(sm) {
                    right: 0;
                    transform: none;
                }
            }

            &::after {
                left: 85%;
                transform: rotate(10deg);
                background-color: $color-green;
            }
        }

        &__content {
            .richtext {
                > * {
                    &:not(.is-h4) {
                        font-family: Lato, serif;
                        font-weight: 300;
                        opacity: 1;
                    }
                }
            }
        }
    }
}
