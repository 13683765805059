form {
    label {
        display: block;
        cursor: pointer;
    }

    select {
        appearance: none;
        user-select: none;

        &.form-control {
            &:not([size], [multiple]) {
                height: 57px;
            }
        }
    }

    .help-block {
        margin: 8px 0 0;
    }
}

.form {
    display: flex;
    flex-direction: column;
    margin: 0 0 40px;

    &-group {
        margin: 0 0 10px;

        label {
            margin: 0 0 10px;
        }

        > label {
            font-weight: 600;
        }

        &.has-error {
            .form-control {
                border-color: $color-red;
            }

            .help-block {
                color: $color-red;
            }
        }
    }

    &-control {
        font-family: $text-font;
        font-size: $text-size;
        line-height: $line-height;
        height: auto;
        padding: 8px 15px;
        border: 2px solid $color-black;
        background-color: $color-white;
        border-radius: 20px;

        &:focus {
            border-color: $color-extra-black;
            background-color: $color-white;
            box-shadow: none;
        }

        &--textarea {
            height: 120px;
        }

        // scss-lint:disable ImportantRule
        &--select {
            @include icon(select, $color-black, 20px, 6px);
            background-position: center right 10px;
            background-color: $color-white;
            padding: 5px 45px 5px 15px;
            height: auto !important;
        }

        // scss-lint:enable ImportantRule

        &--check {
            position: relative;
            padding: 0 0 0 28px;
            margin: 0 0 10px;
            border: 0;
            border-radius: 0;
            background-color: transparent;

            label {
                margin: 0;
            }

            &:last-child {
                margin: 0;
            }

            input[type="radio"],
            input[type="checkbox"] {
                display: none;

                + i {
                    position: absolute;
                    left: 0;
                    top: 7px;
                    display: block;
                    width: 18px;
                    height: 18px;
                    border: 2px solid $color-gray;

                    &::before {
                        position: absolute;
                        top: 1px;
                        left: 1px;
                        display: none;
                        width: 12px;
                        height: 12px;
                        content: "";
                        background-color: $color-red;
                    }
                }

                &:checked {
                    + i {
                        &::before {
                            display: block;
                        }
                    }
                }
            }

            input[type="radio"] {
                + i {
                    border-radius: 50%;

                    &::before {
                        border-radius: 50%;
                    }
                }
            }
        }
    }

    // scss-lint:disable SelectorFormat
    .noUi-target {
        padding: 10px 20px;
        margin: 0 0 26px;
        background-color: transparentize($color-extra-black, .85);
        box-shadow: none;
        border-radius: 23px;
        border: 0;

        &.noUi-horizontal {
            height: 40px;

            .noUi-tooltip {
                bottom: -32px;
            }
        }

        .noUi-connect {
            background: rgba($color-white, .8);
        }

        .noUi-handle {
            border-radius: 50%;
            background-color: $color-white;
            right: -15px;
            width: 30px;
            height: 30px;
            outline: none;
            border: 0;
            cursor: pointer;
            box-shadow: none;

            &::before,
            &::after {
                display: none;
            }
        }

        .noUi-feedback {
            position: absolute;
            left: 0;
            right: 0;
            bottom: -22px;
            display: flex;
            justify-content: space-between;

            .noUi-feedback-min,
            .noUi-feedback-max {
                color: $color-white;
                font-size: 14px;
                line-height: normal;
                font-weight: 500;
            }
        }
    }

    // scss-lint:enable SelectorFormat
}

.form-horizontal {
    .form-group {
        @include make-row;
        margin: 0 0 10px;

        label {
            margin: 0;
        }

        > label {
            @include make-col(3);
            padding: 11px 10px 11px 0;

            @include media-breakpoint-down(sm) {
                @include make-col(12);
                padding: 0;
                margin: 0 0 10px;
            }

            + .form-control-wrapper {
                padding: 0 0 0 10px;

                @include media-breakpoint-down(sm) {
                    padding: 0;
                }
            }
        }
    }

    .form-control-wrapper {
        @include make-col(9);

        @include media-breakpoint-down(sm) {
            @include make-col(12);
        }
    }
}

.form-inline {
    flex-direction: row;
    align-items: baseline;
    margin-left: -10px;
    margin-right: -10px;

    .form-group {
        flex: 1;
        flex-direction: column;
        padding: 0 10px;
        margin: 0 0 10px;

        @include media-breakpoint-down(md) {
            flex: 0 0 100%;
        }

        &:last-child {
            flex: 0;
        }

        &--error {
            flex: 0 0 100%;
            text-align: center;

            .help-block {
                color: $color-red;
                margin: 0;
            }
        }

        .form-control,
        .help-block {
            width: 100%;
        }
    }
}

.form-search {
    position: relative;

    .form-control {
        width: 100%;
        padding-right: 55px;
        border-color: $color-black;
    }

    .btn {
        @include icon(search, $color-red, 16px, 16px);
        position: absolute;
        right: 0;
        bottom: 0;
        display: block;
        width: 48px;
        height: 100%;
        padding: 0;
        font-size: 0;
        border: 0;
        background-color: transparent;
        border-radius: 0;

        &:hover,
        &:focus {
            background-color: transparent;
        }
    }
}

.form-filter {
    flex-flow: row wrap;
    border-radius: 5px;
    background-color: $color-red;
    padding: 30px 30px 20px;

    @include media-breakpoint-down(sm) {
        flex-direction: column;
    }

    .form-group {
        flex: 1;
        padding: 0 15px;

        @include media-breakpoint-down(md) {
            flex: 0 0 50%;
            max-width: 50%;
        }

        @include media-breakpoint-down(sm) {
            flex: 0 0 100%;
            max-width: 100%;
            padding: 0;
        }

        &--large {
            flex: 0 0 100%;
            max-width: 100%;
        }

        &--button {
            flex: 0;

            .btn {
                white-space: nowrap;
                margin: 30px 0 0;
                border: 0;
                padding: 9px 20px;
                background-color: transparentize($color-extra-black, .85);

                &:hover,
                &:focus {
                    background-color: transparentize($color-extra-black, .75);
                }
            }
        }

        > label {
            color: $color-white;
            font-size: 14px;
            line-height: 30px;
            font-weight: 900;
            margin: 0;
            padding: 0 10px;
        }
    }

    .form-control {
        color: $color-white;
        font-size: 14px;
        line-height: 26px;
        border-color: transparentize($color-extra-black, .85);
        background-color: transparentize($color-extra-black, .85);

        &::placeholder {
            color: $color-white;
            font-style: normal;
            font-weight: 500;
        }

        &--select {
            @include icon(select, $color-white, 20px, 6px);
            background-position: center right 10px;
        }

        &--search {
            + .btn {
                @include icon(search, $color-white, 20px, 20px);
                position: absolute;
                right: 0;
                bottom: 0;
                display: block;
                width: 46px;
                height: 100%;
                padding: 0;
                font-size: 0;
                border: 0;
                background-color: transparent;
            }
        }
    }

    .form-control-wrapper {
        position: relative;
    }

    &--light {
        background-color: $color-gray-light;

        .form-group {
            > label {
                color: $color-black;
            }

            &--button {
                .btn {
                    background-color: $color-red;

                    &:hover,
                    &:focus {
                        background-color: darken($color-red, 10%);
                    }
                }
            }
        }

        // scss-lint:disable SelectorFormat
        .noUi-target {
            border-radius: 0;
            background-color: transparent;

            .noUi-connect {
                background-color: transparentize($color-extra-black, .85);
            }

            .noUi-handle {
                background-color: $color-red;
            }

            .noUi-feedback {
                .noUi-feedback-min,
                .noUi-feedback-max {
                    color: $color-black;
                }
            }
        }

        // scss-lint:enable SelectorFormat
    }
}

// scss-lint:disable ImportantRule
.hbspt-form {
    fieldset {
        padding: 0 !important;
        max-width: none !important;
    }

    .hs-main-font-element {
        font-size: 32px;
        line-height: 42px;

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            font-size: 32px;
            line-height: 42px;
            margin: 0 0 15px;
        }
    }

    .hs-form-field {
        @extend .form-group;

        .input {
            margin: 0 !important;
        }
    }

    .form-columns-1 {
        .hs-input {
            width: 100% !important;
        }
    }

    .form-columns-2 {
        margin: 0 -10px;

        .form-group {
            width: 50%;
            padding: 0 10px;

            @include media-breakpoint-down(md) {
                width: 100%;
            }
        }
    }

    .hs-error-msgs {
        .hs-error-msg {
            @extend .help-block;
            color: $color-red !important;
        }
    }

    .no-list,
    .inputs-list {
        margin: 0;
    }

    .hs-input {
        @extend .form-control;

        &.error {
            border-color: $color-red;
        }
    }

    .hs-button {
        @extend .btn;
    }

    .hs-form-booleancheckbox-display {
        &:last-of-type {
            margin: 0;
        }

        input[type="radio"],
        input[type="checkbox"] {
            display: none;

            + span {
                position: relative;
                padding: 0 0 0 25px;
                margin: 0 0 10px;

                &::after {
                    position: absolute;
                    left: 0;
                    top: 5px;
                    display: block;
                    width: 16px;
                    height: 16px;
                    content: "";
                    border: 2px solid $color-gray;
                    margin: 0;
                }

                &::before {
                    position: absolute;
                    top: 8px;
                    left: 3px;
                    display: none;
                    width: 10px;
                    height: 10px;
                    content: "";
                    background-color: $color-red;
                }
            }

            &:checked {
                + span {
                    &::before {
                        display: block;
                    }
                }
            }
        }

        input[type="radio"] {
            + span {
                &::after {
                    border-radius: 50%;
                }

                &::before {
                    border-radius: 50%;
                }
            }
        }
    }
}

// scss-lint:enable ImportantRule

::placeholder {
    color: $color-gray;
    font-style: italic;
}

select::-ms-expand {
    display: none;
}

select option[disabled] {
    display: none;
}

// Landingspage 2

.landingspage-2 {
    .form {
        &-control {
            border-radius: 0;
        }

        // scss-lint:disable SelectorFormat
        .noUi-target {
            border-radius: 0;
        }

        // scss-lint:enable SelectorFormat
    }

    .form-search {
        .btn {
            @include icon(search, $color-blue, 16px, 16px);
        }
    }

    .form-filter {
        border-radius: 0;
        background-color: $color-green;

        &--light {
            background-color: $color-gray-light;

            .form-group {
                &--button {
                    .btn {
                        background-color: $color-blue;

                        &:hover,
                        &:focus {
                            background-color: darken($color-blue, 10%);
                        }
                    }
                }
            }

            // scss-lint:disable SelectorFormat
            .noUi-target {
                .noUi-handle {
                    background-color: $color-blue;
                }
            }

            // scss-lint:enable SelectorFormat
        }
    }
}
