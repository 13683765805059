.block {
    &--article {
        margin: 0;
    }
}

.article {
    $self: &;
    margin: 0 0 ($default-block-margin - $default-margin);

    &__title {
        @include make-col(10);
        display: flex;
        justify-content: center;
        margin: 0 auto 60px;
        flex-direction: column;

        @include media-breakpoint-down(sm) {
            @include make-col(12);
            margin: 0;
        }

        .is-h3 {
            margin-bottom: 10px;
            display: block;
            text-align: center;
            line-height: 55px;
        }

        .is-h2 {
            margin-bottom: 10px;
            display: block;
            text-align: center;
        }

        .info-publish {
            display: block;
            text-align: center;
            margin-top: -10px;
            font-size: 14px;
            line-height: 28px;
        }
    }

    &__content {
        @include make-col(8);
        display: flex;
        flex-direction: column;
        margin: 0 auto $default-margin;
        padding: 0 5px;

        @include media-breakpoint-down(sm) {
            @include make-col(12);
        }

        .richtext {
            margin: 0;
        }

        &--small {
            text-align: center;
            padding: 0 10%;

            @include media-breakpoint-down(md) {
                padding: 0;
            }
        }
    }

    &__image {
        @include make-col(10);
        display: flex;
        flex-direction: column;
        margin: 0 auto 40px;
        padding: 0 5px;
    }

    &__tags {
        @include make-col(8);
        padding: 0 5px;
        margin: 0 auto;

        @include media-breakpoint-down(sm) {
            @include make-col(12);
        }

        + #{ $self }__author {
            padding-top: $default-margin;
            border-top: 1px solid $color-extra-black;
        }
    }

    &__author {
        @include make-col(8);
        padding: 0 5px;
        margin: 0 auto;

        @include media-breakpoint-down(sm) {
            @include make-col(12);
        }
    }
}
