.navigation {
    ul {
        margin: 0;

        &.applications {
            min-height: 360px;

            @include media-breakpoint-down(md) {
                min-height: unset;
            }
        }

        li {
            display: block;
            padding: 0;
            margin: 0;

            a {
                display: block;
                color: $color-black;
                font-size: 16px;
                line-height: 24px;
                padding: 6px 5px;

                &:hover,
                &:focus {
                    color: $color-red;
                    text-decoration: none;
                }
            }

            ul {
                display: none;
            }

            &.active {
                > a {
                    color: $color-red;
                }

                ul {
                    display: block;
                }
            }

            &.has-subnav {
                position: relative;

                > .toggle-subnav {
                    @include icon(arrowthin, $color-red, 12px, 6px);
                    position: absolute;
                    top: 11px;
                    right: 0;
                    display: none;
                    width: 12px;
                    height: 6px;
                    padding: 0;
                    transition: all .3s;

                    @include media-breakpoint-down(md) {
                        display: block;
                    }
                }

                &.active,
                &.subnav-active {
                    > .toggle-subnav {
                        transform: rotate(180deg);
                    }
                }

                &.active.subnav-active {
                    > .toggle-subnav {
                        @include icon(arrowthin, $color-red, 12px, 6px);
                        transform: rotate(0deg);
                    }
                }
            }
        }
    }

    &--header {
        display: flex;
        flex-flow: row wrap;

        @include media-breakpoint-down(md) {
            position: fixed;
            top: 60px;
            left: 100%;
            bottom: 0;
            width: 100%;
            padding: 15px 0;
            margin: 0;
            z-index: 99999;
            display: flex;
            flex-direction: column;
            background-color: $color-black;
            transition: all .3s ease-in-out;
            overflow: auto;
        }

        .primary {
            display: flex;
            justify-content: flex-end;
            margin: 0;
            order: 1;

            @include media-breakpoint-down(md) {
                order: 1;
                flex-direction: column;
                padding: 0 15px;
                margin: 0 0 20px;
            }

            li {
                position: relative;
                margin: 0;

                @include media-breakpoint-down(md) {
                    display: block;
                }

                a {
                    color: $color-white;
                    font-size: 16px;
                    line-height: 30px;
                    text-transform: uppercase;
                    font-weight: 900;
                    padding: 0 10px;
                    transition: all .3s;
                    white-space: nowrap;

                    @include media-breakpoint-down(lg) {
                        padding: 0 5px;
                        font-size: 15px;
                    }

                    @include media-breakpoint-down(md) {
                        padding: 5px 0;
                    }
                }

                ul {
                    position: absolute;
                    left: -99999px;
                    display: block;
                    min-width: 200px;
                    width: 100%;
                    padding: 0 0 10px;
                    background-color: $color-black;
                    border-radius: 0 0 20px 20px;

                    @include media-breakpoint-down(md) {
                        position: static;
                        display: none;
                        width: auto;
                        margin: 0;
                        padding: 0 0 0 20px;
                        background-color: transparent;
                    }

                    li {
                        display: block;
                        margin: 0;
                        padding: 0;

                        a {
                            padding: 10px;
                            line-height: 28px;
                            white-space: normal;
                        }
                    }

                    &.application {
                        &--products {
                            min-height: 100%;
                            align-items: flex-start;
                        }
                    }
                }

                &.is-open,
                &:hover,
                &:focus {
                    > a {
                        color: $color-red;
                    }

                    > ul {
                        left: auto;
                    }
                }

                &.has-subnav {
                    &:hover,
                    &:focus {
                        z-index: 1;
                    }

                    > .toggle-subnav {
                        @include icon(arrowthin, $color-white, 12px, 6px);
                        display: none;

                        @include media-breakpoint-down(md) {
                            display: block;
                            top: 10px;
                            width: 40px;
                            height: 40px;
                        }
                    }

                    &.active {
                        > .toggle-subnav {
                            @include icon(arrowthin, $color-red, 12px, 6px);
                        }
                    }

                    &.active,
                    &.subnav-active {
                        ul {
                            @include media-breakpoint-down(md) {
                                display: block;
                            }
                        }
                    }

                    // scss-lint:disable MergeableSelector
                    &.active.subnav-active {
                        ul {
                            @include media-breakpoint-down(md) {
                                display: none;
                            }
                        }
                    }

                    // scss-lint:enable MergeableSelector

                    // scss-lint:disable NestingDepth
                    &.has-products {
                        position: static;

                        @include media-breakpoint-down(md) {
                            position: relative;
                        }

                        .navigation {
                            &--products {
                                left: -99999px;

                                @include media-breakpoint-down(md) {
                                    display: none;
                                }
                            }
                        }

                        &:hover,
                        &:focus {
                            > a {
                                &::before {
                                    position: absolute;
                                    top: 0;
                                    display: block;
                                    width: 100%;
                                    height: 52px;
                                    content: "";
                                }

                                span {
                                    position: relative;
                                }
                            }

                            .navigation {
                                &--products {
                                    left: 0;
                                }
                            }
                        }

                        &.active,
                        &.subnav-active {
                            .navigation {
                                &--products {
                                    display: flex;
                                }
                            }
                        }

                        // scss-lint:disable MergeableSelector
                        &.active.subnav-active {
                            .navigation {
                                &--products {
                                    @include media-breakpoint-down(md) {
                                        display: none;
                                    }
                                }
                            }
                        }

                        // scss-lint:enable MergeableSelector
                    }

                    // scss-lint:enable NestingDepth
                }
            }

            > li {
                &.has-subnav {
                    > a {
                        position: relative;
                        padding-right: 20px;

                        &::after {
                            @include icon(arrowthin, $color-white, 12px, 6px);
                            position: absolute;
                            top: 50%;
                            right: 0;
                            display: block;
                            width: 15px;
                            height: 14px;
                            content: "";
                            margin: -7px 0 0;
                            transition: all .3s;

                            @include media-breakpoint-down(md) {
                                display: none;
                            }
                        }
                    }

                    &:hover,
                    &:focus,
                    &.active {
                        > a {
                            &::after {
                                @include icon(arrowthin, $color-red, 12px, 6px);

                                @include media-breakpoint-down(md) {
                                    display: none;
                                }
                            }
                        }
                    }

                    &:hover,
                    &:focus {
                        > a {
                            &::after {
                                transform: rotate(180deg);
                            }
                        }
                    }
                }
            }
        }

        .secondary {
            display: flex;
            justify-content: flex-end;
            margin: 0;
            order: 2;

            @include media-breakpoint-down(md) {
                order: 2;
                flex-direction: column;
                padding: 0 15px;
                margin: 0 0 20px;
            }

            li {
                margin: 0;

                > a {
                    padding: 0 10px;
                    font-size: 14px;
                    line-height: 30px;
                    color: $color-white;
                    font-weight: 700;
                    text-transform: none;
                    white-space: nowrap;

                    @include media-breakpoint-down(md) {
                        padding: 5px 0;
                    }
                }

                &:hover,
                &:focus {
                    > a {
                        color: $color-red;
                    }
                }
            }
        }
    }

    &--footer {
        ul {
            display: flex;
            flex-wrap: wrap;

            li {
                display: inline-block;
                margin: 0 5px;

                a {
                    color: transparentize($color-white, .5);
                    font-size: 14px;
                    padding: 0;

                    &:hover,
                    &:focus {
                        color: $color-white;
                        text-decoration: none;
                    }
                }
            }
        }
    }

    // scss-lint:disable ImportantRule
    &--products {
        position: absolute;
        top: 40px;
        left: 0;
        display: flex;
        flex-flow: column wrap;
        width: 100%;
        background-color: $color-black;
        border-radius: 0 0 20px 20px;
        padding: 20px;

        @include media-breakpoint-down(md) {
            position: static;
            background-color: transparent;
            border-top: 0;
            border-bottom: 0;
            padding: 0;
        }

        li {
            &.has-subnav {
                > a {
                    @include media-breakpoint-only(lg) {
                        pointer-events: none;
                    }
                }
            }
        }

        > ul {
            position: relative !important;
            left: auto !important;
            overflow: hidden;

            > li {
                position: initial !important;
                width: 25%;

                @include media-breakpoint-down(md) {
                    width: 100%;
                }

                ul {
                    position: absolute;
                    top: 0;

                    @include media-breakpoint-down(md) {
                        display: none !important;
                    }

                    li {
                        position: initial !important;
                        flex: 0 0 50%;

                        a {
                            text-align: center;
                        }

                        .product-icon {
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            justify-content: center;

                            img {
                                max-width: 30px;
                                padding: 0 0 10px;
                            }

                            span {
                                font-weight: normal;
                                font-size: 15px;
                            }
                        }

                        .image-preview {
                            position: absolute;
                            left: -999999px;
                            top: 0;
                            width: 100%;
                            display: flex;
                            flex-direction: column;
                            color: $color-white;
                            padding: 10px;
                            background: $color-black;

                            .preview-title {
                                font-size: 18px;
                                line-height: 26px;
                                color: $color-red-dark;
                                margin: 10px 0;
                            }

                            .preview-text {
                                font-size: 14px;
                                line-height: 20px;
                                color: $color-white;
                            }

                            img {
                                margin: 0 auto;
                                max-height: 200px;
                            }
                        }

                        &:hover,
                        &:focus {
                            .image-preview {
                                left: 100%;
                            }
                        }
                    }

                    &:not(:hover) {
                        > li:first-child {
                            > a {
                                color: $color-red;
                            }

                            .image-preview {
                                left: 100%;
                            }
                        }
                    }
                }

                &:hover,
                &:focus {
                    width: 100%;

                    ul {
                        left: 25% !important;
                        display: flex;
                        flex-wrap: wrap;
                        align-items: center;
                        width: 35%;
                    }
                }
            }

            &:not(:hover) {
                > li:first-child {
                    width: 100%;

                    > a {
                        color: $color-red;
                    }

                    > ul {
                        left: 25% !important;
                        display: flex;
                        flex-wrap: wrap;
                        align-items: center;
                        width: 35%;

                        > li:first-child {
                            .image-preview {
                                left: 100%;
                            }
                        }
                    }
                }
            }
        }

        .btn {
            position: relative !important;
            top: unset !important;
            display: inline-block !important;
            width: auto !important;
            margin: 0 !important;
            color: $color-white !important;
            padding: 7px 18px !important;
            line-height: 22px !important;
            align-self: flex-start !important;

            @include media-breakpoint-down(md) {
                margin: 0 0 0 30px !important;
            }

            &:hover,
            &:focus {
                color: $color-white !important;
                border-color: darken($color-red, 10%) !important;
                background-color: darken($color-red, 10%) !important;
            }
        }
    }

    // scss-lint:enable ImportantRule
}

.primary-navigation-active {
    .navigation {
        &--header {
            @include media-breakpoint-down(md) {
                left: 0;
            }
        }
    }
}

.primary-search-active {
    .navigation {
        &--header {
            @include media-breakpoint-down(md) {
                top: 128px;
            }
        }
    }
}

// Landingspage

.landingspage {
    .navigation {
        &--header {
            @include media-breakpoint-down(md) {
                top: 70px;
            }
        }
    }
}

// Landingspage 2

// scss-lint:disable NestingDepth
.landingspage-2 {
    .navigation {
        ul {
            li {
                > a {
                    &:hover,
                    &:focus {
                        color: $color-blue;
                    }
                }

                &.active {
                    > a {
                        color: $color-blue;
                    }
                }
            }
        }

        &--header {
            @include media-breakpoint-down(md) {
                background-color: $color-blue;
            }

            ul {
                li {
                    a {
                        color: $color-white;
                        text-transform: uppercase;

                        &:hover,
                        &:focus {
                            color: $color-green;
                        }
                    }

                    ul {
                        background-color: $color-blue;
                        border-radius: 0;
                    }

                    &:hover,
                    &:focus {
                        > a {
                            color: $color-green;
                        }
                    }

                    &.active {
                        > a {
                            color: $color-green;
                        }
                    }

                    &.has-subnav {
                        > a {
                            &::after {
                                @include icon(arrowthin, $color-white, 12px, 6px);
                            }
                        }

                        &:hover,
                        &:focus,
                        &.active {
                            > a {
                                &::after {
                                    @include icon(arrowthin, $color-green, 12px, 6px);
                                }
                            }
                        }

                        > .toggle-subnav {
                            @include icon(arrowthin, $color-white, 12px, 6px);
                        }

                        &.active {
                            > .toggle-subnav {
                                @include icon(arrowthin, $color-green, 12px, 6px);
                            }
                        }
                    }
                }
            }

            .secondary {
                li {
                    > a {
                        font-size: 12px;
                        font-weight: 400;
                    }
                }
            }
        }

        &--footer {
            ul {
                li {
                    a {
                        color: transparentize($color-white, .5);

                        &:hover,
                        &:focus {
                            color: $color-white;
                        }
                    }
                }
            }
        }
    }
}

// scss-lint:enable NestingDepth
