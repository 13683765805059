// Ghostframe block

.block {
    &--ghostframe {
        background-color: $color-red;
        margin: 0 0 $default-block-margin;
        overflow: hidden;

        &:last-of-type {
            margin-bottom: 0;
        }
    }
}

.ghostframe {
    position: relative;
    width: 100%;
    max-width: 1440px;
    height: 660px;
    max-height: 100vh;
    margin: 0 auto;

    @include media-breakpoint-down(md) {
        height: 550px;
    }

    &__frames {
        position: absolute;
        top: 0;
        left: 0;
        width: 30%;
        height: 100%;
        z-index: 3;

        @include media-breakpoint-down(md) {
            width: 75%;
        }

        .frame {
            position: absolute;
            top: 50%;
            left: 0;
            display: block;
            width: 100%;
            padding: $default-margin 40px 0;
            transform: translateY(-50%);
            opacity: 0;

            @include media-breakpoint-down(sm) {
                padding-left: 30px;
                padding-right: 30px;
            }

            &:first-child {
                opacity: 1;
            }

            .is-h2,
            .is-h3 {
                color: $color-white;

                .special {
                    color: $color-white;
                }
            }
        }
    }

    &__loader {
        position: absolute;
        left: 40px;
        bottom: 10%;
        width: 270px;
        height: 5px;
        border-bottom: 1px solid $color-white;
        z-index: 3;

        span {
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            width: 90px;
            height: 4px;
            content: "";
            background-color: $color-white;
        }
    }

    &__screens {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 100%;
        z-index: 1;

        .screen {
            position: absolute;
            top: 50%;
            right: 90px;
            width: 630px;
            height: 505px;
            overflow: hidden;
            perspective: 2000px;
            transform: translateY(-50%);

            @include media-breakpoint-down(md) {
                width: 472.5px;
                height: 378.75px;
                right: 65px;
            }

            @include media-breakpoint-down(sm) {
                width: 290px;
                height: 232px;
                right: 30px;
            }

            img {
                top: 50%;
                left: 50%;
                width: auto;
                height: 90%;
                max-width: none;
                position: absolute;
                transform: translate(-53%, -50%) rotateY(-30deg);
            }

            &--1 {
                margin: -20px -50px 0 0;
                z-index: 1;

                @include media-breakpoint-down(sm) {
                    margin: -15px -15px 0 0;
                }
            }

            &--2 {
                z-index: 2;
            }

            &--3 {
                margin: 20px 50px 0 0;
                z-index: 3;

                @include media-breakpoint-down(sm) {
                    margin: 15px 15px 0 0;
                }
            }
        }
    }

    &__overlay {
        position: absolute;
        top: 0;
        display: block;
        width: 100%;
        height: 100%;
        z-index: 2;

        img {
            position: absolute;
            bottom: 0;
            left: 30%;
            width: auto;
            max-width: none;
            height: 90%;

            @include media-breakpoint-down(sm) {
                left: -10%;
                height: 55%;
            }
        }
    }
}

// Landingspage 2

.landingspage-2 {
    .block {
        &--ghostframe {
            background-color: $color-blue;
        }
    }
}
