.richtext {
    display: flex;
    flex-direction: column;
    margin: 0 0 $default-margin;

    > *:last-child {
        margin-bottom: 0;
    }

    ul:not(.list),
    ol:not(.list) {
        display: block;
        list-style-type: disc;
        padding: 0 0 0 20px;
    }

    ol {
        list-style-type: decimal;
    }

    p,
    address {
        font-weight: 400;
    }

    a:not(.btn) {
        color: $color-red;
        outline: none;

        &:hover,
        &:focus {
            outline: none;
        }
    }

    b,
    strong {
        font-weight: 600;
    }

    i,
    em {
        font-style: italic;
    }

    u {
        text-decoration: underline;
    }

    blockquote {
        padding: 0 12%;
        margin: 25px 0 40px;
        border-left: 0 solid;

        p {
            font-size: 36px;
            line-height: 46px;
            font-weight: 700;
            margin: 0;
        }
    }

    pre {
        font-family: monospace;
    }

    table {
        width: 100%;

        tr {
            &:last-of-type {
                th,
                td {
                    border-bottom: 0;
                }
            }

            th,
            td {
                padding: 5px;
                border-bottom: 1px solid $color-gray;
            }
        }
    }

    img {
        max-width: 100%;
        height: auto;
    }

    hr {
        height: 1px;
        background: $text-color;
        border: 0;
    }

    .table-wrapper {
        margin: 0 0 $default-margin;
        overflow-x: scroll;

        table {
            margin: 0;

            tr {
                th,
                td {
                    @include media-breakpoint-down(md) {
                        min-width: 200px;
                    }
                }
            }
        }
    }

    video {
        @include media-breakpoint-down(md) {
            max-width: 100%;
        }
    }
}

// Landingspage 2

.landingspage-2 {
    .richtext {
        a:not(.btn) {
            color: $color-blue;
        }
    }
}
