// Teaser block

.block {
    &--teaser,
    &--teaser-2,
    &--teaser-3 {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 70px 0 (70px - $default-margin);
        margin: 0 0 $default-block-margin;
        background: $color-red no-repeat center center;
        background-size: cover;

        @include media-breakpoint-down(sm) {
            min-height: 0;
        }

        &:last-of-type {
            margin-bottom: 0;
        }

        &::after {
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            width: 100%;
            height: 100%;
            content: "";
            opacity: .85;
            background-color: $color-red;
            z-index: 0;
        }

        .teaser {
            position: relative;
            color: $color-white;
            z-index: 1;

            .is-h2,
            .is-h3,
            .is-h4,
            .is-h5 {
                color: $color-white;
            }
        }
    }

    &--teaser-2 {
        min-height: 520px;
        background-color: $color-extra-black;

        &::after {
            background-color: $color-extra-black;
        }
    }

    &--teaser-3 {
        min-height: 520px;
        background-color: $color-red;

        &::after {
            background-color: $color-red;
        }
    }
}

.teaser {
    $self: &;
    display: flex;
    flex-direction: column;
    width: 66.6667%;
    margin: 0 auto;

    @include media-breakpoint-down(sm) {
        width: 100%;
    }

    &__title {
        text-align: center;

        .is-h2 {
            text-transform: uppercase;
        }
    }

    &__content {
        text-align: center;
    }

    &__social {
        text-align: center;

        .is-h3 {
            text-transform: uppercase;
        }
    }

    &--small {
        #{ $self }__content {
            max-width: 80%;
            margin: 0 auto;

            @include media-breakpoint-down(sm) {
                max-width: 100%;
            }
        }
    }
}

// Landingspage 2

.landingspage-2 {
    .block {
        &--teaser {
            background-color: $color-blue;

            &::after {
                opacity: .85;
                background-color: $color-blue;
            }

            .teaser {
                &__content {
                    .btn {
                        color: $color-white;
                        border-color: $color-white;
                        background-color: transparent;

                        &:hover,
                        &:focus {
                            color: $color-black;
                            border-color: darken($color-white, 10%);
                            background-color: darken($color-white, 10%);
                        }
                    }
                }
            }
        }

        &--teaser-2 {
            background-color: $color-black-rgba-dark-transparant;

            &::after {
                background-color: $color-black-rgba-dark-transparant;
            }
        }

        &--teaser-3 {
            background-color: $color-green;

            &::after {
                background-color: $color-green;
            }
        }
    }
}
