// Locations block

.block {
    &--locations {
        margin: 0;
    }
}

.locations {
    display: flex;
    flex-direction: column;
    margin: 0 0 ($default-block-margin - $default-margin);

    &__title {
        text-align: center;
    }
}

.location {
    $self: &;

    @include make-row;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    width: 83.3333%;
    margin: 0 auto;

    @include media-breakpoint-down(md) {
        width: auto;
        margin: 0 -15px;
    }

    + #{ $self } {
        margin-top: $default-block-margin - $default-margin;
    }

    &__image {
        @include make-col(5);
        padding: 0 15px;
        margin: 0 auto $default-margin 0;

        @include media-breakpoint-down(sm) {
            @include make-col(12);
        }

        img {
            border-radius: 5px;
            box-shadow: 0 0 20px rgba($color-extra-black, .2);
        }

        a {
            display: block;
            overflow: hidden;
            border-radius: 5px;
            box-shadow: 0 0 20px rgba($color-extra-black, .2);

            img {
                transform: scale(1);
                transition: transform .3s ease-in-out;
                border-radius: 0;
                box-shadow: none;
            }

            &:hover,
            &:focus {
                img {
                    transform: scale(1.1);
                }
            }
        }
    }

    &__content {
        @include make-col(7);
        padding: 0 15px;
        margin: 0 0 0 auto;

        @include media-breakpoint-down(sm) {
            @include make-col(12);
        }

        .is-h3 {
            text-transform: uppercase;

            a {
                color: $text-color;
                text-decoration: none;

                &:hover,
                &:focus {
                    color: $color-red;
                    text-decoration: none;
                }
            }

            + .is-h5 {
                margin-top: -$default-margin;
            }
        }

        .is-h5 {
            font-size: 16px;
            font-weight: 500;
            line-height: 28px;
        }

        .is-h3,
        .is-h5 {
            + .richtext,
            + .list {
                margin-top: -($default-margin / 2);
            }
        }

        .read-more {
            margin-top: -40px;
            font-size: 16px;
            line-height: 28px;
            text-transform: none;
            color: $color-black;
            font-weight: 700;
        }
    }

    &--reversed {
        #{ $self }__image {
            margin-right: 0;
            margin-left: auto;
            order: 2;

            @include media-breakpoint-down(sm) {
                order: 1;
            }
        }

        #{ $self }__content {
            margin-right: auto;
            margin-left: 0;
            order: 1;

            @include media-breakpoint-down(sm) {
                order: 2;
            }
        }
    }
}

.offices {
    @include make-row;
    margin: 0 -15px;

    &__item {
        @include make-col(6);
        padding: 0 15px;

        @include media-breakpoint-down(md) {
            @include make-col(12);
        }
    }
}

.office {
    display: flex;
    flex-direction: column;

    &__content {
        .is-h6 {
            text-transform: uppercase;
            margin: 0;
        }
    }
}

// Landingspage 2

.landingspage-2 {
    .location {
        &__image {
            img {
                border-radius: 0;
            }

            a {
                border-radius: 0;
            }
        }

        &__content {
            .is-h3 {
                a {
                    color: $text-color;

                    &:hover,
                    &:focus {
                        color: $color-blue;
                    }
                }
            }
        }
    }
}
