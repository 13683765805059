// Products block

.block {
    &--products {
        margin: 0;
    }
}

.product {
    $self: &;
    display: flex;
    flex-direction: column;
    margin: 0 0 40px;

    &[href] {
        &:hover,
        &:focus {
            #{ $self }__image {
                img {
                    transform: scale(1.1);
                }
            }
        }
    }

    &__image {
        margin: 0 0 20px;
        overflow: hidden;

        img {
            transform: scale(1);
            transition: transform .3s ease-in-out;
        }
    }

    &__content {
        .is-h2 {
            margin: 0 0 20px;
            font-size: 21px;
            line-height: 23px;
            font-weight: 800;
            color: $color-red;

            + .description {
                margin-top: -20px;
            }
        }

        .description {
            font-size: 17px;
            line-height: 23px;
            font-weight: 800;
            color: $text-color;
            display: block;
            margin: 0 0 20px;
        }

        p {
            font-size: 14px;
            line-height: 23px;
            margin: 0;
        }

        a {
            color: $color-red;

            &:hover,
            &:focus {
                color: $color-red;
                text-decoration: underline;
            }
        }
    }
}
