h1,
.is-h1 {
    color: $color-black;
    font-size: 78px;
    line-height: 88px;
    font-weight: 900;
    font-family: $heading-font;

    @include media-breakpoint-down(sm) {
        font-size: 42px;
        line-height: 52px;
    }
}

h2,
.is-h2 {
    color: $color-black;
    font-size: 58px;
    line-height: 68px;
    font-weight: 900;
    font-family: $heading-font;

    @include media-breakpoint-down(sm) {
        font-size: 32px;
        line-height: 42px;
    }
}

h3,
.is-h3 {
    color: $color-black;
    font-size: 32px;
    line-height: 42px;
    font-weight: 900;
    font-family: $heading-font;
}

h4,
.is-h4 {
    color: $color-black;
    font-size: 24px;
    line-height: 32px;
    font-weight: 900;
    font-family: $heading-font;
}

h5,
.is-h5 {
    color: $color-black;
    font-size: 20px;
    line-height: 28px;
    font-weight: 900;
    font-family: $heading-font;
}

h6,
.is-h6 {
    color: $color-black;
    font-size: 16px;
    line-height: 24px;
    font-weight: 900;
    font-family: $heading-font;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    .special {
        color: $color-red;
    }

    + .info {
        font-size: 14px;
        margin-top: -50px;
    }
}

// Landingspage 2

.landingspage-2 {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-family: $heading-font2;

        .special {
            color: $text-color;
            font-weight: 700;
        }
    }

    h1,
    .is-h1,
    h2,
    .is-h2,
    h3,
    .is-h3 {
        font-weight: 300;
    }

    h4,
    .is-h4,
    h5,
    .is-h5,
    h6,
    .is-h6 {
        font-weight: 900;
    }

    h1,
    .is-h1 {
        font-size: 56px;
        line-height: 66px;

        @include media-breakpoint-down(sm) {
            font-size: 42px;
            line-height: 52px;
        }
    }

    h2,
    .is-h2 {
        font-size: 56px;
        line-height: 66px;

        @include media-breakpoint-down(sm) {
            font-size: 32px;
            line-height: 42px;
        }
    }

    h3,
    .is-h3 {
        font-size: 32px;
        line-height: 42px;
    }

    h4,
    .is-h4 {
        font-size: 24px;
        line-height: 32px;
    }

    h5,
    .is-h5 {
        font-size: 20px;
        line-height: 28px;
    }

    h6,
    .is-h6 {
        font-size: 16px;
        line-height: 24px;
    }
}
