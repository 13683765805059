// Author block

.block {
    &--author {
        margin: 0;
    }
}

.author {
    @include make-row;
    margin: 0 -15px;

    @include media-breakpoint-down(md) {
        justify-content: center;
    }

    &__image {
        flex: 0 0 146px;
        max-width: 146px;
        padding: 0 15px;
        margin: 0 0 40px;

        img {
            border-radius: 50%;
        }
    }

    &__content {
        flex: 1;
        padding: 0 15px;
        margin: 0 0 ($default-margin - 10px);

        .is-h3 {
            margin: 0 0 15px;
            text-transform: uppercase;
        }

        .is-h6 {
            font-size: 14px;
            line-height: 20px;
            text-transform: uppercase;
            margin: 0;
        }

        p {
            font-size: 15px;
            line-height: 22px;
            margin: -15px 0 15px;
        }

        .list {
            li {
                margin-bottom: 10px;
            }
        }
    }
}
