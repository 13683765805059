// Hubspot block

.block {
    &--hubspot-block {
        margin: 0 0 ($default-block-margin - $default-margin);
    }
}

.hubspot-block {
    $self: &;

    @include make-row;
    margin: 0 -15px;

    &__title {
        @include make-col(10);
        padding: 0 15px;
        margin: 0 auto;
        text-align: center;

        @include media-breakpoint-down(sm) {
            @include make-col(12);
        }

        .is-h2 {
            + .is-h3 {
                margin-top: -30px;
            }
        }
    }

    &__content {
        @include make-col(7);
        padding: 0 15px;
        margin: 0 auto;

        @include media-breakpoint-down(md) {
            @include make-col(10);
        }

        @include media-breakpoint-down(sm) {
            @include make-col(12);
        }

        .hbspt-form {
            margin: 0 0 $default-margin;
        }
    }
}
