.list {
    padding: 0;
    margin: 0 0 $default-margin;
    list-style: none;

    li {
        display: block;

        a {
            .info {
                font-style: normal;
            }
        }
    }

    &--usps {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin: 0 -15px;

        li {
            flex: 0 0 33.33%;
            max-width: 33.33%;
            padding: 0 15px;

            @include media-breakpoint-down(md) {
                flex: 0 0 50%;
                max-width: 50%;
            }

            @include media-breakpoint-down(sm) {
                flex: 0 0 100%;
                max-width: 100%;
            }
        }
    }

    &--downloads {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        margin: 0 -15px;

        @include media-breakpoint-down(sm) {
            flex-direction: column;
        }

        li {
            padding: 0 35px;
            margin: 0 0 $default-margin;

            @include media-breakpoint-down(sm) {
                padding: 0 15px;
            }

            a {
                display: block;
                min-height: 60px;
                color: $color-red;
                font-size: 20px;
                line-height: 30px;
                text-decoration: none;
                font-weight: 700;
                text-align: center;
                padding: 3px 30px;
                border: 2px solid $color-red;
                border-radius: 30px;
                transition: all .3s;

                &:hover,
                &:focus {
                    color: $color-white;
                    text-decoration: none;
                    border-color: darken($color-red, 10%);
                    background-color: darken($color-red, 10%);
                }

                span {
                    display: block;
                    font-size: 14px;
                    line-height: 20px;
                    font-weight: 400;
                }
            }
        }
    }

    &--table {
        display: table;
        width: 100%;

        li {
            display: table-row;

            span {
                display: table-cell;
                padding: 3px 0;

                &:first-of-type {
                    padding-right: 40px;
                }

                &:last-of-type {
                    font-weight: 700;
                }
            }
        }
    }

    &--label {
        display: block;

        li {
            color: $text-color;
            font-size: 14px;
            line-height: 30px;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            text-decoration: none;
            text-transform: uppercase;

            .icon {
                width: 30px;
                height: 30px;

                &--date {
                    @include icon(date, $text-color, 20px, 20px);
                }

                &--time {
                    @include icon(time, $text-color, 20px, 20px);
                }

                &--marker {
                    @include icon(marker, $text-color, 20px, 20px);
                }

                &--spec-width {
                    @include icon(specwidth, $text-color, 26px, 26px);
                }

                &--phone {
                    @include icon(phone, $text-color, 20px, 20px);
                }

                &--email {
                    @include icon(email, $text-color, 20px, 20px);
                }
            }
        }
    }

    &--naw {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -5px;

        li {
            padding: 0 5px;
            margin: 0 0 $default-margin;

            a {
                display: flex;
                align-items: center;
                color: $text-color;

                .icon {
                    width: 30px;
                    height: 30px;
                    transition: all .3s ease-in-out;
                    border-radius: 50%;

                    &--instagram {
                        @include icon(instagram, $color-white, 30px, 30px);
                        background-color: $text-color;
                    }

                    &--linkedin {
                        @include icon(linkedin, $color-white, 30px, 30px);
                        background-color: $text-color;
                    }

                    &--email {
                        @include icon(email, $color-white, 30px, 30px);
                        background-color: $text-color;
                    }

                    &--phone {
                        @include icon(phone, $color-white, 30px, 30px);
                        background-color: $text-color;
                    }

                    &--link {
                        @include icon(link, $color-white, 30px, 30px);
                        background-color: $text-color;
                    }
                }

                &:hover,
                &:focus {
                    color: $color-red;

                    .icon {
                        background-color: $color-red;
                    }
                }

                span {
                    margin: 0 0 0 10px;
                    font-size: 14px;
                    line-height: normal;
                    font-weight: 500;

                    @include media-breakpoint-down(sm) {
                        display: none;
                    }
                }
            }
        }
    }

    &--tags {
        display: flex;
        flex-wrap: wrap;
        margin: 0 0 30px;

        li {
            margin: 0 10px 10px 0;

            &::before {
                display: none;
            }

            a {
                display: inline-block;
                color: $text-color;
                border: 2px solid $text-color;
                font-size: 14px;
                line-height: 28px;
                padding: 0 14px;
                font-weight: 500;
                background-color: transparent;
                text-decoration: none;
                border-radius: 20px;

                &:hover,
                &:focus {
                    color: $color-white;
                    text-decoration: none;
                    background-color: $text-color;
                }
            }
        }
    }

    &--social-share {
        display: flex;
        flex-wrap: wrap;
        margin: 0 0 20px;

        li {
            flex: 0 0 50%;
            max-width: 50%;
            margin: 0;

            @include media-breakpoint-down(sm) {
                flex: 0 0 100%;
                max-width: 100%;
            }

            a {
                position: relative;
                display: block;
                color: $text-color;
                padding: 8px 10px 8px 35px;

                &:hover,
                &:focus {
                    text-decoration: underline;
                }

                &::before {
                    position: absolute;
                    top: 4px;
                    left: 0;
                    display: block;
                    width: 30px;
                    height: 30px;
                    content: "";
                }
            }

            &.facebook {
                a {
                    &::before {
                        @include icon(facebook, $text-color, 40px, 40px);
                    }
                }
            }

            &.twitter {
                a {
                    &::before {
                        @include icon(twitter, $text-color, 30px, 30px);
                    }
                }
            }

            &.youtube {
                a {
                    &::before {
                        @include icon(youtube, $text-color, 40px, 40px);
                    }
                }
            }

            &.linkedin {
                a {
                    &::before {
                        @include icon(linkedin, $text-color, 40px, 40px);
                    }
                }
            }

            &.instagram {
                a {
                    &::before {
                        @include icon(instagram, $text-color, 40px, 40px);
                    }
                }
            }

            &.pinterest {
                a {
                    &::before {
                        @include icon(pinterest, $text-color, 40px, 40px);
                    }
                }
            }

            &.whatsapp {
                a {
                    &::before {
                        @include icon(whatsapp, $text-color, 20px, 20px);
                    }
                }
            }

            &.email {
                a {
                    &::before {
                        @include icon(email, $text-color, 40px, 40px);
                    }
                }
            }
        }
    }

    &--cta {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -5px 20px;

        @include media-breakpoint-down(xs) {
            flex-direction: column;
        }

        li {
            position: relative;
            margin: 0 5px 15px;

            button {
                position: relative;
                padding-right: 40px;

                &::after {
                    @include icon(arrowthin, $color-white, 15px, 15px);
                    position: absolute;
                    top: 50%;
                    right: 13px;
                    display: block;
                    width: 15px;
                    height: 14px;
                    content: "";
                    margin: -7px 0 0;
                    transition: all .3s;
                }
            }

            .btn {
                @include media-breakpoint-down(xs) {
                    width: 100%;
                }
            }

            ul {
                position: absolute;
                left: -99999px;
                display: block;
                width: 250px;
                padding: 0;
                margin: 10px 0 0;
                border: 1px solid $color-black;
                background-color: transparent;
                border-radius: 5px;
                overflow: hidden;
                z-index: 1;

                @include media-breakpoint-down(xs) {
                    width: 100%;
                }

                li {
                    display: block;
                    margin: 0;
                    border-bottom: 1px solid $color-black;

                    &:last-child {
                        border-bottom: 0;
                    }

                    a {
                        display: block;
                        padding: 10px 15px;
                        color: $color-black;
                        font-weight: 500;
                        background-color: $color-white;

                        &:hover,
                        &:focus {
                            color: $color-white;
                            text-decoration: none;
                            background-color: $color-red;
                        }
                    }
                }
            }

            &.active {
                button {
                    &::after {
                        transform: rotate(180deg);
                    }
                }

                ul {
                    left: 50%;
                    transform: translateX(-50%);
                }
            }
        }
    }

    &--tabs {
        display: flex;
        flex-wrap: wrap;
        background-color: $color-red;
        box-shadow: 0 0 20px rgba($color-black, .4);
        border-radius: 5px;
        padding: 5px 0;

        @include media-breakpoint-down(sm) {
            padding: 0 5px;
        }

        li {
            position: relative;
            padding: 0 5px;

            @include media-breakpoint-down(sm) {
                flex: 0 0 100%;
                max-width: 100%;
                padding: 5px 0;
            }

            a {
                display: block;
                color: $color-white !important;
                font-weight: 500;
                text-transform: uppercase;
                text-decoration: none;
                padding: 10px 13px;
                border-radius: 5px;
                font-size: 13px;
                cursor: pointer;

                &:hover,
                &:focus {
                    color: $color-white;
                    background-color: transparentize($color-extra-black, .85);
                }
            }

            &.active {
                a {
                    color: $color-white;
                    background: transparentize($color-extra-black, .85);
                }
            }

            &::after {
                position: absolute;
                right: 0;
                top: 6px;
                bottom: 6px;
                display: block;
                width: 1px;
                content: "";
                background-color: transparentize($color-extra-black, .85);

                @include media-breakpoint-down(sm) {
                    top: auto;
                    left: 6px;
                    right: 6px;
                    bottom: 0;
                    width: auto;
                    height: 1px;
                }
            }

            &:last-of-type {
                &::after {
                    display: none;
                }
            }
        }
    }

    &--sites {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -5px 10px;

        li {
            flex: 0 0 50%;
            max-width: 50%;
            padding: 0 5px;
            margin: 0 0 10px;
            text-align: left;

            @include media-breakpoint-down(sm) {
                flex: 0 0 100%;
                max-width: 100%;
            }

            // scss-lint:disable SelectorFormat
            &.web-en {
                a {
                    &::after {
                        @include icon(globe, $color-white, 22px, 22px);
                        background-size: 22px;
                        margin: -6px 0 0 -2px;
                        width: 22px;
                        height: 22px;
                    }
                }
            }

            &.web-en-gb {
               a {
                   &::after {
                       background: url('/assets/img/countries/gb.gif') no-repeat center center;
                   }
               }
            }

            &.web-be {
               a {
                   &::after {
                       background: url('/assets/img/countries/be.gif') no-repeat center center;
                   }
               }
            }

            &.web-cz {
               a {
                   &::after {
                       background: url('/assets/img/countries/cz.gif') no-repeat center center;
                   }
               }
            }

            &.web-de {
               a {
                   &::after {
                       background: url('/assets/img/countries/de.gif') no-repeat center center;
                   }
               }
            }

            &.web-es {
               a {
                   &::after {
                       background: url('/assets/img/countries/es.gif') no-repeat center center;
                   }
               }
            }

            &.web-fi {
               a {
                   &::after {
                       background: url('/assets/img/countries/fi.gif') no-repeat center center;
                   }
               }
            }

            &.web-fr {
               a {
                   &::after {
                       background: url('/assets/img/countries/fr.gif') no-repeat center center;
                   }
               }
            }

            &.web-hu {
               a {
                   &::after {
                       background: url('/assets/img/countries/hu.gif') no-repeat center center;
                   }
               }
            }

            &.web-it {
               a {
                   &::after {
                       background: url('/assets/img/countries/it.gif') no-repeat center center;
                   }
               }
            }

            &.web-nl {
               a {
                   &::after {
                       background: url('/assets/img/countries/nl.gif') no-repeat center center;
                   }
               }
            }

            &.web-no {
               a {
                   &::after {
                       background: url('/assets/img/countries/no.gif') no-repeat center center;
                   }
               }
            }

            &.web-pl {
               a {
                   &::after {
                       background: url('/assets/img/countries/pl.gif') no-repeat center center;
                   }
               }
            }

            &.web-sv {
               a {
                   &::after {
                       background: url('/assets/img/countries/sv.gif') no-repeat center center;
                   }
               }
            }

            &.web-fr-be {
               a {
                   &::after {
                       background: url('/assets/img/countries/be.gif') no-repeat center center;
                   }
               }
            }

            &.web-dk {
               a {
                   &::after {
                       background: url('/assets/img/countries/dk.gif') no-repeat center center;
                   }
               }
            }

            &.web-cn {
               a {
                   &::after {
                       background: url('/assets/img/countries/cn.gif') no-repeat center center;
                   }
               }
            }

            // scss-lint:enable SelectorFormat

            a {
                position: relative;
                display: block;
                color: $color-white;
                text-decoration: none;
                padding: 7px 15px 7px 45px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                background-color: $color-black;
                border-bottom: 1px solid $color-white;
                font-weight: 500;

                &::after {
                    position: absolute;
                    top: 15px;
                    left: 15px;
                    display: block;
                    width: 16px;
                    height: 11px;
                    content: "";
                }

                &:hover,
                &:focus {
                    text-decoration: underline;
                }
            }
        }
    }

    &--highlights {
        margin: 0 0 ($default-margin - 5px);

        li {
            margin: 0 0 5px;

            a {
                color: $color-black;
                font-weight: 600;
                text-decoration: none;

                &:hover,
                &:focus {
                    color: $color-black;
                    text-decoration: underline;
                }
            }

            p,
            .is-h6 {
                margin: 0;
            }

            .info {
                font-size: 14px;
                font-weight: 400;
                font-style: normal;
            }
        }
    }
}

// Landingspage 2

.landingspage-2 {
    .list {
        &--downloads {
            li {
                a {
                    border-width: 1px;
                }
            }
        }

        &--cta {
            li {
                button {
                    padding-right: 41px;
                }

                ul {
                    border-radius: 0;

                    li {
                        a {
                            &:hover,
                            &:focus {
                                background-color: $color-blue;
                            }
                        }
                    }
                }
            }
        }

        &--tabs {
            border-radius: 0;
            background-color: $color-green;

            li {
                a {
                    border-radius: 0;
                }
            }
        }
    }
}
