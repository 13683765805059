// Main Slider block

.block {
    &--main-slider {
        position: relative;
        margin: 0 0 $default-block-margin;

        .btn-scroll-down {
            position: absolute;
            bottom: 10px;
            left: 50%;
            display: block;
            color: $color-white;
            font-size: 14px;
            font-weight: 900;
            padding: 10px 0 30px;
            transition: all .3s ease-in-out;
            transform: translateX(-50%);
            z-index: 99;

            &::before,
            &::after {
                @include icon(arrowthin, $color-white, 10px, 10px);
                position: absolute;
                left: 50%;
                display: block;
                width: 10px;
                height: 10px;
                content: "";
                margin: 0 0 0 -5px;
                transition: all .3s ease-in-out;
            }

            &::before {
                bottom: 16px;
            }

            &::after {
                bottom: 10px;
            }

            &:hover,
            &:focus {
                color: $color-white;
                padding-bottom: 40px;

                &::before {
                    bottom: 10px;
                }

                &::after {
                    bottom: 4px;
                }
            }
        }
    }
}

.main-slider-item {
    $self: &;
    position: relative;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    height: calc(100vh - 130px);
    min-height: 575px;
    overflow: hidden;
    margin: 0;

    @include media-breakpoint-down(sm) {
        height: calc(100vh - 59px);
    }

    &__video {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        opacity: 0;
        transition: opacity .3s;

        .plyr {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
        }
    }

    &__background {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
        background-color: $color-gray-light;
        opacity: 1;
        transition: opacity .3s;

        @include media-breakpoint-down(sm) {
            height: 100%;
        }

        img {
            position: absolute;
            top: 50%;
            left: 50%;
            min-height: 100%;
            min-width: 100%;
            width: auto;
            height: auto;
            max-width: none;
            transform: translate(-50%, -50%);
        }
    }

    &__content {
        position: absolute;
        top: 50%;
        right: 45%;
        left: 10%;
        width: 45%;
        padding: 0 15px;
        margin: 0 0 20px;
        transform: translateY(-50%);
        opacity: 1;
        transition: opacity .3s;

        @include media-breakpoint-down(md) {
            left: 12%;
            right: 12%;
            width: 76%;
        }

        @include media-breakpoint-down(sm) {
            left: 0;
            right: 0;
            width: 100%;
            padding: 0 50px;
        }

        .is-h2 {
            color: $color-white;
            font-size: 64px;
            line-height: 64px;
            text-transform: uppercase;
            margin: 0 0 20px;

            @include media-breakpoint-down(sm) {
                font-size: 42px;
                line-height: 52px;
            }
        }

        .richtext {
            color: $color-white;
            margin: 0 0 20px;

            > * {
                margin: 0 0 20px;

                &:last-child {
                    margin: 0;
                }
            }
        }

        .is-h1,
        .is-h2,
        .richtext {
            text-shadow: 0 3px 3px transparentize($color-extra-black, .8);

            @include media-breakpoint-down(sm) {
                text-shadow: none;
            }
        }
    }

    .btn {
        &--video-play,
        &--video-pause {
            &:hover,
            &:focus {
                transform: scale(1.1);
            }
        }

        &--video-pause {
            position: absolute;
            top: 40px;
            right: 40px;
        }
    }

    &--dark {
        #{ $self }__content {
            .is-h1,
            .is-h2,
            .richtext {
                color: $color-black;
            }

            .btn {
                &--video-play {
                    color: $color-black;

                    &::before {
                        @include icon(playbtn, $color-black, 20px, 20px);
                        border-color: $color-black;
                    }
                }
            }
        }
    }

    &.video-active {
        #{ $self }__video {
            opacity: 1;
            z-index: 1;
        }

        #{ $self }__background,
        #{ $self }__content {
            opacity: 0;
            z-index: 0;
        }
    }
}

// scss-lint:disable ImportantRule

// scss-lint:disable NestingDepth
.main-slider {
    $self: &;
    display: flex !important;
    justify-content: center;
    margin: 0;
    padding: 0;

    &__item {
        position: relative;
        display: block;
        flex: 0 0 100%;
        max-width: 100%;

        &:nth-child(1) {
            ~ #{ $self }__item {
                display: none;
            }
        }
    }

    &.owl-carousel {
        .owl-stage {
            #{ $self }__item {
                flex: 0 0 100%;
                max-width: 100%;
            }
        }

        .owl-nav {
            left: 0;
            right: 0;

            button {
                &.owl-prev,
                &.owl-next {
                    @include icon(arrow-big, $color-white, 22px, 34px);
                    width: 40px;
                    height: 40px;

                    @include media-breakpoint-down(sm) {
                        @include icon(arrow-big, $color-white, 12px, 18px);
                        width: 30px;
                        height: 30px;
                    }
                }
            }
        }

        .owl-item {
            .main-slider-item {
                &__background {
                    img {
                        transform: translate(-50%, -50%) scale(1);
                        transition: 10s;
                        width: auto !important;
                        height: auto !important;
                    }
                }
            }

            &.active {
                z-index: 9999;

                .main-slider-item {
                    &--animate {
                        .main-slider-item {
                            &__background {
                                img {
                                    transform: translate(-50%, -50%) scale(1.15);
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

// scss-lint:enable NestingDepth

// scss-lint:enable ImportantRule
