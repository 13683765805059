// Call To Action block

.block {
    &--cta-block {
        margin: 0;
    }
}

.cta-block {
    $self: &;

    @include make-row;
    margin: 0 -15px ($default-block-margin - $default-margin - $default-margin);

    &__content {
        @include make-col(7);
        padding: 0 15px;
        margin: 0 auto;
        text-align: center;

        @include media-breakpoint-down(md) {
            @include make-col(10);
        }

        @include media-breakpoint-down(sm) {
            @include make-col(12);
        }

        .btn {
            margin: 0 0 $default-margin;

            @include media-breakpoint-down(xs) {
                margin-left: auto;
                margin-right: auto;
            }

            + .btn {
                margin-left: 15px;

                @include media-breakpoint-down(xs) {
                    margin-left: auto;
                    margin-right: auto;
                }
            }
        }
    }
}
