// People block

.block {
    &--peoples {
        margin: 0;
    }
}

.peoples {
    display: flex;
    flex-direction: column;
    margin: 0 0 ($default-block-margin - $default-margin);

    &__title {
        text-align: center;
    }
}

.peoples-overview {
    @include make-row;
    margin: 0 -15px;

    &__item {
        @include make-col(3);
        padding: 0 15px;

        @include media-breakpoint-down(md) {
            @include make-col(6);
        }

        @include media-breakpoint-down(sm) {
            @include make-col(12);
        }
    }
}

.profile {
    $self: &;
    position: relative;
    display: flex;
    border-radius: 5px;
    overflow: hidden;
    box-shadow: 0 0 20px rgba($color-extra-black, .2);
    margin: 0 0 $default-margin;

    &:hover,
    &:focus {
        #{ $self }__content {
            opacity: 1;
        }
    }

    &__content {
        position: absolute;
        top: 10px;
        left: 10px;
        right: 10px;
        opacity: 0;
        transition: opacity .3s;

        .is-h4 {
            color: $color-white;
            margin: 0 0 10px;

            @include media-breakpoint-only(lg) {
                font-size: 21px;
                line-height: normal;
            }

            + p {
                margin-top: -10px;
            }
        }

        p {
            color: $color-white;
            font-weight: 500;
            margin: 0 0 10px;
        }

        .social-follow {
            &--list {
                justify-content: left;
                margin: 0 -5px 10px;

                li {
                    margin: 0 5px;
                }
            }
        }
    }

    &__overlay {
        position: absolute;
        bottom: 5px;
        left: 5px;
        right: 5px;
        color: $color-white;
    }

    .list {
        &--label {
            margin: 0;

            li {
                color: $color-white;

                a {
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    font-weight: 500;
                    color: $color-white;
                    text-decoration: none;

                    &:hover,
                    &:focus {
                        color: $color-white;
                        text-decoration: underline;
                    }
                }

                .icon {
                    &--marker {
                        @include icon(marker, $color-white, 20px, 20px);
                    }

                    &--phone {
                        @include icon(phone, $color-white, 20px, 20px);
                    }

                    &--email {
                        @include icon(email, $color-white, 20px, 20px);
                    }
                }
            }
        }
    }
}

// Landingspage 2

.landingspage-2 {
    .profile {
        border-radius: 0;
    }
}
